import React, { useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import {
  Card,
  Col,
  Form,
  Input,
  Row,
  Select,
  Upload,
  UploadFile,
  message,
  notification,
} from "antd";
import { Button } from "antd";
import { Container } from "react-bootstrap";
import moment from "moment";
import { API } from "../../config/api";
import { FILE_UPLOAD, GET, POST, PUT } from "../../utils/apiCalls";
import { UploadOutlined } from "@ant-design/icons";

function ViewLeaveDetails() {
  const navigate = useNavigate();
  const { id } = useParams();
  const [form] = Form.useForm();

  const [file, setFile] = useState<any>([]);
  const [leaveRequestData, setLeaveRequestData] = useState<any>();
  useEffect(() => {
    getAllLeaveRequestData();
  }, []);

  const getAllLeaveRequestData = async () => {
    try {
      let api = API.POST_LEAVE_REQUEST + id;
      const Data: any = await GET(api, {});
      setLeaveRequestData(Data[0]);
    } catch (error) {
      console.log("Error fetching leave request data:", error);
    }
  };

  const onFinish = async () => {
    try {
      console.log("Form submitted");
      let obj = {
        document: String(file),
      };

      let api = API.UPDATE_LEAVE_REQUEST + id;
      const postRequest: any = await PUT(api, obj);

      if (postRequest.status) {
        notification.success({
          message: "Leave request updated successfully",
        });
        form.resetFields();
        setFile([]);
        navigate("/Auth/leaves");
      } else {
        notification.error({ message: "Something went wrong" });
      }
    } catch (error) {
      console.log(error);
      notification.error({
        message: "Something went wrong! Please try again later",
      });
    }
  };

  const UploadFile = async (data: any) => {
    try {
      console.log(data);
      const myFile = data.file;
      let file = myFile.originFileObj;
      const name = moment(new Date()).unix();
      const myNewFile = new File([file], name + ".png", {
        type: myFile.type,
      });
      const url = URL.createObjectURL(myNewFile);
      let _upload = await FILE_UPLOAD(myNewFile);
      console.log("upload --> ", _upload);
      setFile(_upload);
      notification.success({
        message: "Upload Successful",
        description: "Your document has been successfully uploaded.",
      });
    } catch (err) {
      console.log("UploadFile = = = >", err);
    }
  };
  const leaveType = leaveRequestData?.type;
  return (
    <>
      <Container>
        <Form form={form} onFinish={onFinish}>
          <Card>
            <div className="productAdd-Txt1" style={{ fontSize: "20px" }}>
              Leave Request Details
            </div>
            <Row gutter={[16, 16]}>
              <Col span={6}>
                <strong>Leave type:</strong>
              </Col>
              <Col span={18}>
                <span>{leaveRequestData?.type}</span>
              </Col>
            </Row>

            <Row gutter={[16, 16]}>
              <Col span={6}>
                <strong>From:</strong>
              </Col>
              <Col span={18}>
                <span>
                  {moment(leaveRequestData?.from).format("DD-MM-YYYY")}
                </span>
              </Col>
            </Row>

            <Row gutter={[16, 16]}>
              <Col span={6}>
                <strong>To:</strong>
              </Col>
              <Col span={18}>
                <span>{moment(leaveRequestData?.to).format("DD-MM-YYYY")}</span>
              </Col>
            </Row>
            <Row gutter={[16, 16]}>
              <Col span={6}>
                <strong>Remark:</strong>
              </Col>
              <Col span={18}>
                <span>{leaveRequestData?.remark}</span>
              </Col>
            </Row>

            {leaveRequestData?.type === "Sick leave" && (
              <>
                {" "}
                <Col span={6}>
                  <strong>Document</strong>
                </Col>
                <Form.Item
                  name={"file"}
                  rules={[
                    {
                      required: leaveType === "Sick leave",
                      message:
                        "Uploading a document is required for sick leaves",
                    },
                  ]}
                >
                  <Upload onChange={(data) => UploadFile(data)}>
                    <div
                      style={{
                        marginLeft: 10,
                        width: "100%",
                      }}
                    >
                      <UploadOutlined color="red" /> Click to Upload
                    </div>
                  </Upload>
                </Form.Item>
                <Col md={3}>
                  <Form.Item>
                    <Button
                      size="large"
                      style={{ width: "100%" }}
                      type="primary"
                      htmlType="submit"
                    >
                      Update
                    </Button>
                  </Form.Item>
                </Col>
              </>
            )}
          </Card>
        </Form>
      </Container>
    </>
  );
}

export default ViewLeaveDetails;
