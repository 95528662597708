import { Card, Pagination, Space, Table, TableProps, Tag } from "antd";
import moment from "moment";
import React, { useRef, useState } from "react";
import { Container } from "react-bootstrap";
import { MdRemoveRedEye } from "react-icons/md";
import { useNavigate } from "react-router-dom";

const DataTable = (props: any) => {
  const dataGridRef: any = useRef(null);
  const [selectedRows, setSelectedRows] = useState();

  const navigate = useNavigate();

  const onSelectionChanged = (e: any) => {
    setSelectedRows(e.selectedRowsData.length);
  };

  const calculateLeaveDuration = (
    fromDate: string,
    toDate: string,
    durationType: string
  ) => {
    const startDate = moment(fromDate);
    const endDate = moment(toDate);

    if (durationType === "Half day") {
      return 0.5;
    } else {
      const duration = endDate.diff(startDate, "days") + 1;
      return duration;
    }
  };

  interface DataType {
    key: string;
    name: string;
    tags: string[];
    remark: string;
  }

  const columns: TableProps<DataType>["columns"] = [
    {
      title: "Name",
      dataIndex: "employeeId",
      key: "employeeId",
      render: (_: any, record: any) => (
        <span>{record?.employee?.fullName}</span>
      ),
    },
    {
      title: "Type",
      dataIndex: "type",
      key: "type",
    },
    {
      title: "From",
      dataIndex: "from",
      key: "from",
      render: (text: any) => <span>{moment(text).format("DD-MM-YYYY")}</span>,
    },
    {
      title: "To",
      dataIndex: "to",
      key: "to",
      render: (text: any) => <span>{moment(text).format("DD-MM-YYYY")}</span>,
    },

    {
      title: "No. of Days",
      render: (_: any, record: any) => (
        <span>
          {calculateLeaveDuration(record.from, record.to, record.duration)}
        </span>
      ),
    },

    {
      title: "Status",
      key: "status",
      dataIndex: "status",
      render: (text: any) => {
        const statusColor =
          text === "approved"
            ? "green"
            : text === "pending"
            ? "orange"
            : text === "rejected"
            ? "blue"
            : "orange";
        return (
          <Tag color={statusColor}>{text ? text.toUpperCase() : "PENDING"}</Tag>
        );
      },
    },

    {
      title: "Action",
      key: "id",
      render: (_: any, record: any) => (
        <Space size="middle">
          <MdRemoveRedEye
            color="blue"
            size={20}
            onClick={() =>
              navigate(`/Auth/leaves/ViewLevaeDetails/${record?.id}`)
            }
          />
        </Space>
      ),
    },
  ];

  // const data: DataType[] = props.list.data

  return (
    <Container>
      <h5>Leave History</h5>
      <br />

      <h6>Total {props?.meta?.total_count} leave</h6>

      <br />
      <Card>
        <Table
          columns={columns}
          dataSource={props?.datasource}
          pagination={false}
        />
      </Card>

      <Pagination
        total={props?.meta?.total_count}
        pageSize={props?.meta?.take}
        onChange={(page, pageSize) => props?.onPageChange(page, pageSize)}
        style={{
          display: props?.meta?.total_count > 10 ? "block" : "none",
          textAlign: "right",
          marginTop: "20px",
        }}
      />
    </Container>
  );
};
export default DataTable;
