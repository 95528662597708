// import { useEffect, useState } from "react";
// import { useSelector } from "react-redux";
// import { API } from "../../config/api";
// import { GET, POST } from "../../utils/apiCalls";
// import DataTable from "./Table";

// function Task() {
//   const [isLoading, setIsLoading] = useState(false);
//   const [taskData, setTaskData] = useState<any>([]); // Ensure taskData is initialized as an array
//   const User = useSelector((state: any) => state.User.user);

//   console.log("taskData===>", taskData);

//   useEffect(() => {
//     getTaskData();
//   }, );

//   const getTaskData = async (values: any) => {
//     try {
//       setIsLoading(true);

//       let objs = {
//         projectId: Number(values.projectId),
//         assignedTo: Number(values.assignedTo),
//       };
//       let api = API.GET_FILTER_TASK + "?order=ASC&page=1&take=10" + User.id;
//       const data: any = await POST(api, objs);
//       console.log("taskData", data);
//       // let obj = {
//       //   userId: Number(User.id),
//       // };
//       if (data?.data) {
//         setTaskData([data?.data]);
//       } else {
//         console.error("Data is not an array:", data?.data);
//       }
//     } catch (error) {
//       console.log(error);
//     } finally {
//       setIsLoading(false);
//     }
//   };

//   return (
//     <div>
//       <DataTable dataSource={taskData} />
//     </div>
//   );
// }
// export default Task;

import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { API } from "../../config/api";
import { POST } from "../../utils/apiCalls";
import DataTable from "./Table";
import { useParams } from "react-router-dom";
import { useForm } from "antd/es/form/Form";
import PageHeader from "../pageheader/pageheader";

function Task() {
  const { id } = useParams();
  const [isLoading, setIsLoading] = useState(false);
  const [taskData, setTaskData] = useState<any>([]);
  const [form] = useForm();
  const [page, setPage] = useState(1);
  const [take, setTake] = useState(4);
  const [meta, setMeta] = useState<any>({});

  const User = useSelector((state: any) => state.User.user);

  useEffect(() => {
    if (User && User.id) {
      getTaskData(User.id);
    }
  }, [User]);

  const getTaskData = async (userId: number) => {
    try {
      setIsLoading(true);
      const takeValue = Math.min(100, 10);
      let objs = {
        projectId: Number(id),
        assignedTo: userId,
        query: form.getFieldValue("query"),
        page: page,
        take: take,
      };
      let api = API.POST_PAGE_TASK;
      const data: any = await POST(api, objs);

      if (data?.data) {
        setTaskData(data?.data);
        setMeta(data?.meta);
      } else {
        console.error("Data is not available:", data);
      }
    } catch (error) {
      console.log(error);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div>
      <PageHeader title="TASK DEATILS" />

      <DataTable
        dataSource={taskData}
        reload={() => getTaskData(User.id)}
        isLoading={isLoading}
        meta={meta}
        onPageChange={(page: number, pageSize: number) => {
          setIsLoading(true);
          setPage(page);
          setTake(pageSize);
        }}
      />
    </div>
  );
}

export default Task;
