import React, { useEffect, useState } from "react";
import DataTable from "./Table";
import { API } from "../../config/api";
import { GET, POST } from "../../utils/apiCalls";
import { useSelector } from "react-redux";
import PageHeader from "../pageheader/pageheader";

function Project() {
  const [isLoading, setIsLoading] = useState(false);
  const [memberData, setMemberData] = useState([]);
  const [page, setPage] = useState(1);
  const [take, setTake] = useState(10);
  const [meta, setMeta] = useState<any>({});
  const [membermeta, setMemberMeta] = useState<any>({});
  const User = useSelector((state: any) => state.User.user);
  console.log("usr", User.id);

  const GetOneUser = async (values: any) => {
    try {
      setIsLoading(true);

      let obj = {
        userId: Number(User.id),
        page: page,
        take: take,
      };

      const url = API.MEMBER_LIST;
      const members: any = await POST(url, obj);
      console.log("qwerty", members);
      if (members.status) {
        setMemberData(members?.data);
        setMemberMeta(members.meta);
      } else {
        setMemberData([]);
      }
    } catch (error) {
      console.log(error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    GetOneUser(User.id);
  }, []);

  return (
    <div>
      <PageHeader title="PROJECT DETAILS" />
      <br />
      <br />

      <div>
        {/* <DataTable
          datasource={memberData}
          meta={meta}
          onPageChange={(page: number, pageSize: number) => {
            setIsLoading(true);
            setPage(page);
            setTake(pageSize);
          }}
        /> */}
        <DataTable
          // columns={columns}
          list={memberData}
          isLoading={isLoading}
          meta={meta}
          onPageChange={(page: number, pageSize: number) => {
            setIsLoading(true);
            setPage(page);
            setTake(pageSize);
          }}
        />
      </div>
    </div>
  );
}

export default Project;
