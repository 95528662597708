import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import { Card } from "antd";

const Help = () => {
  return (
    <div className="screen-box">
      <div className="main-box">
        <div className="main-txt">Help Screen</div>
      </div>
    </div>
  );
};

export default Help;
