import { LoadingOutlined } from "@ant-design/icons";
import { Button, Card, Form, Input, Spin } from "antd";
import React, { useEffect, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";

function BankDetails(props: any) {
  const [isLoading, setIsLoading] = useState(false);
  const [form] = Form.useForm();

  useEffect(() => {
    console.log("bankName --> ", props?.user?.bankName);

    form.setFieldsValue({
      bankName: props?.user?.bankName,
      accountName: props?.user?.accountName,
      branch: props?.user?.branch,
      accountNo: props?.user?.accountNo,
      ifsc: props?.user?.ifsc,
    });
  }, [props?.user, form]); // Added props.user and form as dependencies

  const submit = async (values: any) => {
    console.log("Submit", values);
    setIsLoading(true);
    let obj = {
      bankName: values.bankName,
      accountName: values.accountName,
      branch: values.branch,
      accountNo: values.accountNo,
      ifsc: values.ifsc,
    };

    try {
      await props.update(obj);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Container>
      <Form form={form} onFinish={submit}>
        <Card>
          <Row>
            <Col md="6">
              <label className="formLabel">Bank Name</label>
              <Form.Item
                name="bankName"
                style={{ marginBottom: 10 }}
                rules={[{ required: true }]}
              >
                <Input size="large" placeholder="" />
              </Form.Item>
            </Col>
            <Col md="6">
              <label className="formLabel">Account Name</label>
              <Form.Item
                name="accountName"
                style={{ marginBottom: 10 }}
                rules={[{ required: true }]}
              >
                <Input size="large" placeholder="" />
              </Form.Item>
            </Col>
            <Col md="6">
              <label className="formLabel">Branch</label>
              <Form.Item
                name="branch"
                style={{ marginBottom: 10 }}
                rules={[{ required: true }]}
              >
                <Input size="large" placeholder="" />
              </Form.Item>
            </Col>
            <Col md="6">
              <label className="formLabel">Account No</label>
              <Form.Item
                name="accountNo"
                style={{ marginBottom: 10 }}
                rules={[{ required: true }]}
              >
                <Input size="large" placeholder="" />
              </Form.Item>
            </Col>
            <Col md="6">
              <label className="formLabel">IFSC Code</label>
              <Form.Item
                name="ifsc"
                style={{ marginBottom: 10 }}
                rules={[{ required: true }]}
              >
                <Input size="large" placeholder="" />
              </Form.Item>
            </Col>
          </Row>
          <br />
          <Row>
            <Col md="6"></Col>
            <Col md="3">
              <Form.Item>
                <Button size="large" style={{ width: "100%" }}>
                  Cancel
                </Button>
              </Form.Item>
            </Col>
            <Col md="3">
              <Form.Item>
                <Button
                  size="large"
                  style={{ width: "100%" }}
                  type="primary"
                  htmlType="submit"
                  disabled={isLoading}
                >
                  {isLoading ? (
                    <Spin
                      indicator={
                        <LoadingOutlined
                          style={{ fontSize: 24, color: "#fff" }}
                          spin
                        />
                      }
                    />
                  ) : (
                    "Update"
                  )}
                </Button>
              </Form.Item>
            </Col>
          </Row>
        </Card>
      </Form>
    </Container>
  );
}

export default BankDetails;
