import React, { useEffect, useState } from "react";
import PageHeader from "../pageheader/pageheader";
import DataTable from "./Table";
import { API } from "../../config/api";
import { POST } from "../../utils/apiCalls";
import { useForm } from "antd/es/form/Form";
import { useSelector } from "react-redux";
import { DatePicker, Spin } from "antd";

function Documents() {
  const [form] = useForm();
  const [PayrollData, setPayrollData] = useState<any>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [page, setPage] = useState(1);
  const [take, setTake] = useState(10);
  const [meta, setMeta] = useState<any>({});
  console.log("PayrollData", PayrollData);
  const User = useSelector((state: any) => state.User.user);

  const containerStyle = {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: "100vh",
  };

  const getAllPayrollData = async (date: any) => {
    try {
      setIsLoading(true);
      let obj = {
        userId: Number(User.id),
        id: Number(User.id),
        query: form.getFieldValue("query"),
        page: page,
        take: 10,
        date: date,
      };
      console.log("--------", obj);

      let api = API.POST_HR_PAYROLL;
      const response: any = await POST(api, obj);
      console.log("Data history", response);

      if (response) {
        setPayrollData(response?.data);
        setIsLoading(false);
      } else {
        console.error("Failed to fetch data from the API.");
        setIsLoading(false);
      }
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    getAllPayrollData(new Date());
  }, []);

  const onChange = (data: any) => {
    console.log(data);
    getAllPayrollData(data);
  };

  return (
    <div>
      {isLoading ? (
        <Spin style={containerStyle} />
      ) : (
        <>
          <PageHeader title="DOCUMENTS" />
          <br />

          <div className="">
            <DatePicker onChange={onChange} picker="month" />
          </div>
          <DataTable
            list={PayrollData}
            isLoading={isLoading}
            meta={meta}
            onPageChange={(page: number, pageSize: number) => {
              setIsLoading(true);
              setPage(page);
              setTake(pageSize);
            }}
          />
        </>
      )}
    </div>
  );
}
export default Documents;
