import { Card } from "antd";
import React from "react";
import { Row, Col } from "react-bootstrap";
import Header from "../../components/header";

const Dashboard = () => {
  return (
    <div className="screen-box">
      <div className="main-box">
        <div className="main-txt">
          <Header
            header="Dashboard"
            btn={false}
            onBtnClick={() => console.log("hello")}
          />
        </div>
      </div>
    </div>
  );
};

export default Dashboard;
