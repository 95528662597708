import { Button, Popover } from "antd";
import { RiUserSmileLine } from "react-icons/ri";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { logout } from "../redux/slice/userSlice";
import { FiBell } from "react-icons/fi";

function LayoutHeader(props: any) {
  const User = useSelector((state: any) => state.User.user);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handleLogout = () => {
    dispatch(logout());
    navigate("/");
  };

  return (
    <div className="LayoutHeader">
      <div style={{ flex: 1 }}>
        {/* <Button type="primary">Quick Access</Button>
        &nbsp; &nbsp;
        <Button>10-1212 1212</Button> */}
      </div>
      <div className="LayoutHeader-box">
        <FiBell
          size={25}
          onClick={() => navigate("/Auth/notification")}
          style={{ color: " rgb(28, 112, 110)" }}
        />
        &nbsp; &nbsp;&nbsp; &nbsp;
        <div className="" style={{ color: " rgb(28, 112, 110)" }}>
          {User.fullName}
        </div>
        &nbsp; &nbsp;&nbsp; &nbsp;
        <div>
          <Popover
            placement="bottomLeft"
            content={
              <div>
                <div
                  style={{ cursor: "pointer" }}
                  onClick={() => navigate("/Auth/profile")}
                >
                  Manage Account
                </div>
                <br />
                <div
                  style={{ cursor: "pointer", color: "red" }}
                  onClick={handleLogout}
                >
                  Logout
                </div>
              </div>
            }
          >
            <RiUserSmileLine
              fontSize={40}
              style={{ color: " rgb(28, 112, 110)" }}
            />
          </Popover>
        </div>
      </div>
    </div>
  );
}

export default LayoutHeader;
