import { Button, Card } from "antd";
import React from "react";
import { Row, Col } from "react-bootstrap";
import "./styles.scss";
import { IoArrowBackOutline } from "react-icons/io5";

const Header = (props: any) => {
  return (
    <div className="header">
      <div className="headerBox">
        <Row>
          <Col md="1">
            <div className="headerBackBtn">
              <IoArrowBackOutline color="grey" size={40} />
            </div>
          </Col>
          <Col md="3">
            <div className="headerHeadBox">
              <div className="headerTxt1">{props?.header}</div>
              <div className="headerTxt2">header2</div>
            </div>
          </Col>
          <Col md="6"></Col>
          <Col md="2">
            {props?.btn ? <div className="headerButton">Create</div> : null}
          </Col>
        </Row>
      </div>
    </div>
  );
};

export default Header;
