import {
  Button,
  Card,
  DatePicker,
  Form,
  Input,
  Pagination,
  Select,
  Spin,
  Tag,
  Upload,
  UploadFile,
  notification,
} from "antd";
import { useForm } from "antd/lib/form/Form";
import React, { useEffect, useState } from "react";
import { API } from "../../config/api";
import { FILE_UPLOAD, GET, POST } from "../../utils/apiCalls";
import { useNavigate, useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import DataTable from "./Table";
import { Col, Container, Row } from "react-bootstrap";
import { LoadingOutlined, UploadOutlined } from "@ant-design/icons";
import moment from "moment";
import PageHeader from "../pageheader/pageheader";

const Leaves = () => {
  const [form] = useForm();
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();
  const [leaveData, setLeaveData] = useState<any>([]);

  const [leavefilterData, setLeavefilterData] = useState<any>([]);
  const [page, setPage] = useState(1);
  const [take, setTake] = useState(10);
  const [meta, setMeta] = useState<any>({});
  const [file, setFile] = useState<any>([]);
  const [fromDate, setFromDate] = useState<any>("");
  const [toDate, setToDate] = useState<Date>();
  const [selectedLeaveType, setSelectedLeaveType] = useState<any>();
  const [durationType, setDurationType] = useState("");

  console.log("data--->", durationType);

  const User = useSelector((state: any) => state.User.user);

  const reload = () => {
    fetchHrLeave();
  };

  const [leaveBalance, setLeaveBalance] = useState({
    // totalCasualLeavesAllowed: 0,
    totalLeavesTaken: 0,
    leaveBalance: 0,
    totalCasualLeavesTaken: 0,
    pendingLeaveRequests: 0,
  });

  const [leaveSummary, setLeaveSummary] = useState({
    type: "",
  });

  const onFormChange = (changedValues: any, allValues: any) => {
    setSelectedLeaveType(allValues.type);
    setLeaveSummary({
      type: allValues.type,
    });
  };
  const calculateLeaveDuration = () => {
    if (!fromDate || !toDate) return 0;

    const startDate = moment(fromDate);
    const endDate = moment(toDate);

    if (durationType === "Half day") {
      return 0.5;
    } else {
      const duration = endDate.diff(startDate, "days") + 1;
      return duration;
    }
  };

  const getCasualLeavesCount = () => {
    const currentYear = moment().year();
    const casualLeavesThisYear = leaveData.filter(
      (entry: any) =>
        entry.type === "Casual leave" &&
        moment(entry.createdAt).year() === currentYear
    );
    return casualLeavesThisYear.length;
  };

  const getAllData = async () => {
    try {
      let api = API.GET_LEAVE_COUNT + User.id;
      const count: any = await GET(api, {});
      if (count?.status) {
        setLeaveBalance(count?.data);
      } else {
        console.error("Failed to fetch data from the API.");
      }
    } catch (err) {
      console.log(err);
    }
  };
  useEffect(() => {
    getAllData();
  }, []);
  const fetchHrLeave = async () => {
    try {
      setIsLoading(true);
      let obj = {
        employeeId: User.id,
        query: form.getFieldValue("query"),
        page: Number(page),
        take: 10,
      };
      const url = API.POST_PAGE_LEAVE;
      const response: any = await POST(url, obj);
      console.log("=========", response);
      setLeaveData(response?.data);
      setMeta(response?.meta);
      console.log("Data ---> ", response);
      setIsLoading(false);
    } catch (error) {
      console.log(error);
    } finally {
      setIsLoading(false);
    }
  };
  useEffect(() => {
    fetchHrLeave();
  }, [page, take]);

  const handleReset = () => {
    form.resetFields();
  };
  const onFinish = (values: any) => {
    console.log("Received values:", values);

    const leaveDuration = calculateLeaveDuration();

    if (
      values.type === "Casual leave" &&
      leaveBalance.totalCasualLeavesTaken + leaveDuration > 12
    ) {
      notification.error({
        message: "You cannot apply for more than 12 casual leaves.",
      });
      return;
    }

    submitHandler(values);
  };

  const submitHandler = async (values: any) => {
    try {
      console.log("values --> ", values);

      let obj = {
        type: values.type,
        description: values.description,
        from: values.from.format("YYYY-MM-DD"),
        to: values.to.format("YYYY-MM-DD"),

        employeeId: Number(User.id),
        document: String(file),
        duration: values.duration,
        createdBy: "User",
      };
      console.log(obj);
      let api = API.POST_LEAVE_REQUESTS;

      let postRequest: any = await POST(api, obj);
      if (postRequest.status) {
        notification.success({
          message: "Leave request submitted successfully",
        });

        form.resetFields();
        setFile([]);
        getAllData();
        fetchHrLeave();
        setLeaveSummary({
          type: "",
        });
        setFromDate("");
        setToDate(undefined);
      } else {
        notification.error({ message: "Something went wrong" });
      }
      reload();
    } catch (error) {
      console.log(error);
      notification.error({
        message: "Something went wrong! Please try again later",
      });
    }
  };
  const UploadFile = async (data: any) => {
    try {
      console.log(data);
      const myFile = data.file;
      let file = myFile.originFileObj;
      const name = moment(new Date()).unix();
      const myNewFile = new File([file], name + ".png", {
        type: myFile.type,
      });
      const url = URL.createObjectURL(myNewFile);
      let _upload = await FILE_UPLOAD(myNewFile);
      console.log("upload --> ", _upload);
      setFile(_upload);
    } catch (err) {
      console.log("UploadFile = = = >", err);
    }
  };

  const isDurationGreaterThanOneDay =
    moment(toDate).diff(moment(fromDate), "days") >= 1;

  return (
    <>
      <div className="screen-box">
        <PageHeader title="LEAVE DEATILS" />
        <br />
        <Container>
          <Row gutter={16}>
            <Col span={6}>
              <Card title="Total Leaves Taken" bordered={false}>
                {leaveBalance.totalLeavesTaken}
              </Card>
            </Col>
            <br />
            <Col span={6}>
              <Card title="Total Casual Leaves Taken" bordered={false}>
                {leaveBalance.totalCasualLeavesTaken}
              </Card>
            </Col>
            <br />
            <Col span={6}>
              <Card title="Leave Balance" bordered={false}>
                {leaveBalance.leaveBalance}
              </Card>
            </Col>
            <br />
            <Col span={6}>
              <Card title="Pending Leave Requests" bordered={false}>
                {leaveBalance.pendingLeaveRequests}
              </Card>
            </Col>
            <br />
          </Row>
        </Container>
      </div>
      <div className="screen-box">
        <Container>
          <Row>
            <Col md={8}>
              <Card style={{ height: "90%" }}>
                <Form
                  onFinish={onFinish}
                  form={form}
                  layout="vertical"
                  onValuesChange={onFormChange}
                >
                  <Row gutter={9}>
                    <Col md={3}>
                      <Form.Item
                        label="Leave Type"
                        name="type"
                        rules={[
                          { required: true, message: "Select leave type" },
                        ]}
                      >
                        <Select
                          style={{ width: "100%" }}
                          size="large"
                          placeholder="Select leave type"
                        >
                          <Select.Option value="Sick leave">
                            Sick leave
                          </Select.Option>
                          <Select.Option value="Casual leave">
                            Casual leave
                          </Select.Option>
                          <Select.Option value="Maternity leave">
                            Maternity leave
                          </Select.Option>
                        </Select>
                      </Form.Item>
                    </Col>
                    <Col md={3}>
                      <Form.Item
                        label="From"
                        name="from"
                        rules={[{ required: true, message: "Select Date" }]}
                      >
                        <DatePicker
                          size="large"
                          onChange={(val: any) =>
                            setFromDate(val.toISOString())
                          }
                        />
                      </Form.Item>
                    </Col>
                    <Col md={3}>
                      <Form.Item
                        label="To"
                        name="to"
                        rules={[{ required: true, message: "Select Date" }]}
                      >
                        <DatePicker
                          size="large"
                          onChange={(val: any) => setToDate(val.toISOString())}
                        />
                      </Form.Item>
                    </Col>

                    <Col md={3}>
                      <Form.Item
                        label="Leave duration"
                        name="duration"
                        rules={[
                          { required: true, message: "Select leave duration" },
                        ]}
                      >
                        <Select
                          onChange={(value) => setDurationType(value)}
                          size="large"
                          placeholder="Select leave duration"
                        >
                          <Select.Option
                            value="Half day"
                            disabled={isDurationGreaterThanOneDay}
                          >
                            Half day
                          </Select.Option>
                          <Select.Option value="Full day">
                            Full day
                          </Select.Option>
                        </Select>
                      </Form.Item>
                    </Col>

                    <Col md={5}>
                      <Form.Item
                        label="Description"
                        name="description"
                        rules={[
                          { required: true, message: "Fill Description" },
                        ]}
                      >
                        <Input.TextArea size="large" rows={3} />
                      </Form.Item>
                    </Col>

                    <Col md={3}>
                      {selectedLeaveType === "Sick leave" && (
                        <>
                          <label className="formLabel">Document</label>
                          <Form.Item name={"file"}>
                            <Upload onChange={(data) => UploadFile(data)}>
                              <div
                                style={{
                                  marginLeft: 10,
                                  width: "100%",
                                }}
                              >
                                <UploadOutlined color="red" /> Click to Upload
                              </div>
                            </Upload>
                          </Form.Item>
                        </>
                      )}
                    </Col>
                  </Row>

                  <Row gutter={9}>
                    <Col md={7} />
                    <Col md={2}>
                      <Form.Item>
                        <Button
                          size="large"
                          style={{ width: "100%" }}
                          onClick={handleReset}
                        >
                          Clear
                        </Button>
                      </Form.Item>
                    </Col>
                    <Col md={3}>
                      <Form.Item>
                        <Button
                          size="large"
                          style={{ width: "100%" }}
                          type="primary"
                          htmlType="submit"
                          disabled={isLoading}
                        >
                          {isLoading ? (
                            <Spin
                              indicator={
                                <LoadingOutlined
                                  style={{ fontSize: 24, color: "#fff" }}
                                  spin
                                />
                              }
                            />
                          ) : (
                            "Submit"
                          )}
                        </Button>
                      </Form.Item>
                    </Col>
                  </Row>
                </Form>
              </Card>
            </Col>

            <br />

            <Col md={4}>
              <Card title="Leave Summary" style={{ height: "90%" }}>
                <p>
                  <strong>Type:</strong> {leaveSummary.type}
                </p>
                <p>
                  <strong>From:</strong>{" "}
                  {fromDate ? moment(fromDate).format("ll") : ""}
                </p>
                <p>
                  <strong>To:</strong>{" "}
                  {toDate ? moment(toDate).format("ll") : ""}
                </p>
                <p>
                  {/* {durationType === "Half day" ? 0.5 : moment(toDate).diff(moment(fromDate), "days") + 1} */}
                  <p>
                    <strong>Leave Duration:</strong> {calculateLeaveDuration()}
                  </p>
                  {/* {moment(toDate).diff(moment(fromDate), "days") + 1}
                   */}
                </p>
                <p>
                  <strong>Leave Balance:</strong> {leaveBalance.leaveBalance}
                </p>
                <p>
                  <strong>Total Casual Leave Taken:</strong>{" "}
                  {leaveBalance.totalCasualLeavesTaken}
                </p>
              </Card>
            </Col>
          </Row>
        </Container>

        <Container>
          <Card>
            <DataTable
              datasource={leaveData}
              filerData={leavefilterData}
              isLoading={isLoading}
              meta={meta}
              onPageChange={(page: number, pageSize: number) => {
                setIsLoading(true);
                setPage(page);
                setTake(pageSize);
              }}
            />
          </Card>
        </Container>

        {/* <div style={{ textAlign: "center", marginTop: "10px" }}> */}
        {/* <Pagination
            current={page}
            pageSize={meta.take}
            total={meta.itemCount}
            onChange={pagination}
          /> */}
        {/* </div> */}
      </div>
    </>
  );
};

export default Leaves;
