import React, { useEffect, useState } from "react";
import { Col, Container, Row, Modal, Form, Button } from "react-bootstrap";
import { useSelector } from "react-redux";
import { GET, POST } from "../../utils/apiCalls";
import { API } from "../../config/api";
import { useNavigate } from "react-router-dom";
import Calendar from "react-calendar";
import "react-calendar/dist/Calendar.css";
import "./styles.scss";
import { Card, Radio, notification } from "antd";
import moment from "moment";
import { ReconciliationOutlined, TagOutlined } from "@ant-design/icons";

const DashBoard = () => {
  const [events, setEvents] = useState<any>([]);
  const [selectedDate, setSelectedDate] = useState<Date | null>(new Date());
  const [eventDescription, setEventDescription] = useState("");
  const [showModal, setShowModal] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [reminders, setReminders] = useState<any>([]);
  const user = useSelector((state: any) => state.User.user);
  const [currentDateEvents, setCurrentDateEvents] = useState<any>([]);
  const navigate = useNavigate();
  const [modalMode, setModalMode] = useState("view");
  const [priority, setPriority] = useState<string>("low");

  console.log("currentDateEvents", currentDateEvents);
  const [count, setCount] = useState<any>({
    projects: 0,
    currentTasks: 0,
    overdueTasks: 0,
    dueTasks: 0,
  });
  const today = moment().format("YYYY-MM-DD");

  const filteredEvents = Array.isArray(currentDateEvents)
    ? currentDateEvents.filter(
        (event: any) => moment(event.date).format("YYYY-MM-DD") === today
      )
    : [];

  useEffect(() => {
    getAllData();
    getCalendar();
  }, []);

  const handleCloseModal = () => {
    setShowModal(false);
    setModalMode("view");
    setSelectedDate(null);
    setEventDescription("");
    setPriority("low");
  };

  const getAllData = async () => {
    try {
      let api = API.GET_USRDASHBOARD + user.id;
      const count: any = await GET(api, {});
      if (count?.status) {
        setCount(count?.data);
      } else {
        console.error("Failed to fetch data from the API.");
      }
    } catch (err) {
      console.log(err);
    }
  };

  const getCalendar = async () => {
    try {
      const api = API.GET_CALENDAR + `date/${selectedDate}/employee/${user.id}`;
      console.log("formattedDate", selectedDate);
      const response: any = await GET(api, {});
      console.log("response", response);
      if (response) {
        setEvents(response?.data);
        setCurrentDateEvents(response?.data);
      } else {
        console.log("hellooooooo");
      }
    } catch (error) {
      console.error("Error fetching calendar events:", error);
    }
  };

  const handleDateClick = (date: Date) => {
    setSelectedDate(date);
    setShowModal(true);
  };

  const handleSaveEvent = async (val: any) => {
    try {
      setIsLoading(true);
      const obj = {
        title: eventDescription,
        date: moment(selectedDate).format("YYYY-MM-DD"),
        description: eventDescription,
        time: String(val.time),
        employeeId: Number(user.id),
        reminders: String(val.reminders),
        priority,
        status: true,
      };
      const url = API.CALENDAR;
      const data = await POST(url, obj);
      console.log("eventsave", data);
      if (data) {
        notification.success({
          message: "Success",
          description: "Calendar Event Successfully Created",
        });
        setEvents([...events, obj]);
        setIsLoading(false);
        handleCloseModal();
        console.log("eventsave", data);
      } else {
        notification.error({
          message: "Failed",
          description: "Failed to create calendar event",
        });
        setIsLoading(false);
      }
    } catch (err) {
      console.log("Error:", err);
      notification.error({
        message: "Oops!",
        description: "Server error",
      });
      setIsLoading(false);
    }
  };

  const tileContent = ({ date, view }: any) => {
    if (view === "month" && Array.isArray(events)) {
      const eventsForDate = events?.filter((event) => {
        const eventDate = new Date(event?.date);
        return (
          eventDate?.getDate() === date?.getDate() &&
          eventDate?.getMonth() === date?.getMonth() &&
          eventDate?.getFullYear() === date?.getFullYear()
        );
      });

      if (eventsForDate?.length > 0) {
        return (
          <div
            className="event-marker"
            style={{
              backgroundColor: "red",
              borderRadius: "50%",
              width: "10px",
              height: "10px",
              margin: "0 auto",
            }}
          ></div>
        );
      }
    }
    return null;
  };

  return (
    <div className="screen-box">
      <div className="main-box">
        <div className="main-txt"></div>
        <Container>
          <Row>
            <Col md={3}>
              <Card>
                <div>
                  {" "}
                  <ReconciliationOutlined />
                  Total Projects
                </div>
                <div>{count.projects}</div>
              </Card>
            </Col>
            <Col md={3}>
              <Card>
                <div>
                  {" "}
                  <TagOutlined />
                  Current Tasks
                </div>
                <div>{count.currentTasks}</div>
              </Card>
            </Col>
            <Col md={3}>
              <Card>
                <div>
                  {" "}
                  <TagOutlined />
                  Outstanding Tasks
                </div>
                <div>{count.overdueTasks}</div>
              </Card>
            </Col>
            <Col md={3}>
              <Card>
                <div>
                  {" "}
                  <TagOutlined />
                  Due Tasks
                </div>
                <div>{count.dueTasks}</div>
              </Card>
            </Col>
          </Row>
        </Container>
        <div>
          <br></br>
          <Container>
            <Row>
              <Col md={6}>
                <div className="calendar-container">
                  <Calendar
                    tileContent={tileContent}
                    onClickDay={handleDateClick}
                    value={events?.map((event: any) => new Date(event?.date))}
                    onChange={(value, event) => {
                      if (Array.isArray(value)) {
                        console.log("Selected Date =====> ", value);
                        setSelectedDate(value[0]);
                      } else {
                        console.log("Selected Date =====> ", value);
                        setSelectedDate(value);
                      }
                    }}
                  />
                </div>
              </Col>
              <Col md="6">
                <div className="event">
                  <Card>
                    <div>Today's Events:</div>
                    <hr />
                    {filteredEvents?.length > 0 ? (
                      filteredEvents?.map((event: any, index: any) => (
                        <div key={index}>
                          <div>{event?.description}</div>
                        </div>
                      ))
                    ) : (
                      <p>No events for today</p>
                    )}
                  </Card>
                </div>
              </Col>
            </Row>
          </Container>

          <Modal show={showModal} onHide={handleCloseModal}>
            <Modal.Header closeButton>
              <Modal.Title>
                {modalMode === "add"
                  ? "Add Event"
                  : `Events for ${moment(selectedDate).format(
                      "MMMM Do, YYYY"
                    )}`}
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              {modalMode === "add" ? (
                <Form onSubmit={handleSaveEvent}>
                  <Form.Group controlId="event">
                    <Form.Label>Event Description</Form.Label>
                    <Form.Control
                      as="textarea"
                      rows={3}
                      value={eventDescription}
                      onChange={(e) => setEventDescription(e?.target?.value)}
                    />
                  </Form.Group>

                  <br />
                  <Button variant="primary" type="submit">
                    Save Event
                  </Button>
                </Form>
              ) : (
                events
                  ?.filter((event: any) =>
                    moment(event.date).isSame(selectedDate, "day")
                  )
                  ?.map((event: any, index: number) => (
                    <Card key={index}>
                      <div>{event.title}</div>
                      <div>{event.description}</div>
                      <div style={{ color: "red" }}></div>
                    </Card>
                  ))
              )}
            </Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" onClick={handleCloseModal}>
                Close
              </Button>
              <Button variant="primary" onClick={() => setModalMode("add")}>
                Add Event
              </Button>
            </Modal.Footer>
          </Modal>
        </div>
      </div>
    </div>
  );
};

export default DashBoard;
