import React from "react";
import { Route, Routes } from "react-router-dom";
import Dashboard from "../admin/dashboard";

import { useSelector } from "react-redux";
import ProtectedRoute from "./protectRouter";
import { Layout } from "antd";
import SideBar from "./sideBar";
import LayoutHeader from "./layoutHeader";
import { useNavigate } from "react-router-dom";
import Courses from "../admin/courses";
import Users from "../admin/users";
import Category from "../admin/category";
import Enrolements from "../admin/enrolements";
import Settings from "../admin/settings";

const AdminNavigation = () => {
  const User = useSelector((state: any) => state.User);
  const { Sider, Content } = Layout;
  return (
    <>
      <Layout>
        <Sider
          style={{ backgroundColor: "#04286f", height: "100vh" }}
          width={250}
        >
          <SideBar />
        </Sider>
        <Layout>
          <LayoutHeader />
          <Content style={{ background: "#f5fffd" }}>
            <Routes>
              <Route path="/" element={<Dashboard />} />
              <Route path="/admin-dashboard" element={<Dashboard />} />
              <Route path="/admin-courses" element={<Courses />} />
              <Route path="/admin-usres" element={<Users />} />
              <Route path="/admin-category" element={<Category />} />
              <Route path="/admin-enrolements" element={<Enrolements />} />
              <Route path="/admin-settings" element={<Settings />} />
            </Routes>
          </Content>
        </Layout>
      </Layout>
    </>
  );
};

export default AdminNavigation;
