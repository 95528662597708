import { Card, Pagination } from "antd";
import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import { useNavigate } from "react-router-dom";

const DataTable = (props: any) => {
  const navigate = useNavigate();

  const capitalizeFirstLetter = (string: string) => {
    return string.charAt(0).toUpperCase() + string.slice(1);
  };

  const titleStyle = { fontSize: "16px", marginBottom: "10px" };

  const renderCard = (record: any) => {
    let priorityStyle;
    switch (record?.project?.priority) {
      case "high":
        priorityStyle = {
          backgroundColor: "#FFCACA",
          color: "#FF0000",
          fontSize: "12px",
        };
        break;
      case "medium":
        priorityStyle = {
          backgroundColor: "#FFF6C9",
          color: "#FFA500",
          fontSize: "12px",
        };
        break;
      case "low":
        priorityStyle = {
          backgroundColor: "#C9FFCA",
          color: "#008000",
          fontSize: "12px",
        };
        break;
      default:
        priorityStyle = {};
    }

    return (
      <Col key={record.project.id} md={3} style={{ marginBottom: "20px" }}>
        <div>
          <Container>
            <Card
              onClick={() =>
                navigate(`/Auth/project/task-details/${record?.project?.id}`)
              }
              style={{
                width: "100%",
                maxWidth: "300px",
                height: "100%",
              }}
              bodyStyle={{ height: "100%" }}
            >
              <div style={titleStyle}>
                <h3>{capitalizeFirstLetter(record?.project?.title)}</h3>
              </div>
              <p>{record?.project?.description}</p>
              <div
                style={{
                  ...titleStyle,
                  ...priorityStyle,
                  padding: "5px",
                  borderRadius: "3px",
                  display: "inline-block",
                }}
              >
                {record?.project?.priority}
              </div>
            </Card>
          </Container>
        </div>
      </Col>
    );
  };

  return (
    <div style={{ padding: "20px" }}>
      <Container>
        <Card>
          <h6>Total {props.list.length} projects</h6>
        </Card>
      </Container>
      <br />
      <Container>
        <Card style={{ padding: "20px" }}>
          <Row>{props.list.map((record: any) => renderCard(record))}</Row>
        </Card>
      </Container>
      <Pagination
        total={props.meta.total_count}
        pageSize={props.meta.take}
        onChange={(page, pageSize) => props.onPageChange(page, pageSize)}
        style={{
          display: props.meta.total_count > 10 ? "block" : "none",
          textAlign: "right",
          marginTop: "20px",
        }}
      />
    </div>
  );
};

export default DataTable;
