import React from "react";
import Header from "../../components/header";

const Settings = () => {
  return (
    <div className="screen-box">
      <div className="main-box">
        <div className="main-txt">
          <Header
            header="Settings"
            btn={false}
            onBtnClick={() => console.log("hello")}
          />
        </div>
      </div>
    </div>
  );
};

export default Settings;
