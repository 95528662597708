import { createSlice } from "@reduxjs/toolkit";
const AuthSlice = createSlice({
  name: "Auth",
  initialState: {
    user: {},
    auth: false,
    admin: true,
  },
  reducers: {
    login: (state, action) => {
      state.user = action.payload;
      if (action.payload?.type === 'Admin') {
        state.admin = true;
      } else {
        state.admin = false;
      }
      state.auth = true;
    },

    logout: (state) => {
      state.user = {};
      state.auth = false;
      state.auth = false;
    },

    update: (state, action) => {
      state.user = action.payload;
    },
  },
});

export const { login, logout, update } = AuthSlice.actions;
export default AuthSlice.reducer;
