import { useNavigate, useLocation } from "react-router-dom";
import Menu from "./menu.json";
import "./styles.scss";
import DynamicIcon from "./dymanicIcon";
import Logo from "../assets/images/bairuha-new-logo-logo-White.png";

function SideBar() {
  const navigate = useNavigate();
  let location = useLocation();
  return (
    <div className="sideBar-Box">
      <div className="sideBar-logo">
        <img alt="E-Learn" src={Logo} />
      </div>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "stretch",
        }}
      >
        <div className="sideBar-txt1">MAIN MENUS</div>
        {Menu?.slice(0, 7).map((item: any) => {
          return (
            <div
              key={item?.id}
              onClick={() => navigate(item?.navigate)}
              className={
                location.pathname === item?.navigate
                  ? "sideBar-item active"
                  : "sideBar-item"
              }
            >
              <DynamicIcon icon={item?.icon} />
              <div style={{ marginLeft: 20 }} />
              <div>{item?.title}</div>
            </div>
          );
        })}
      </div>
    </div>
  );
}

export default SideBar;
