import {
  Button,
  Card,
  DatePicker,
  Form,
  Input,
  Select,
  Spin,
  Upload,
} from "antd";
import form from "antd/es/form";
import React, { useEffect, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import dayjs from "dayjs";

import moment from "moment";
import { FILE_UPLOAD } from "../../../utils/apiCalls";
import { EditOutlined, LoadingOutlined } from "@ant-design/icons";

function PersonalDetails(props: any) {
  const [file, setFile] = useState<any>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [upload, setUpload] = useState("");
  console.log(props.data);
  const onFinish = () => {
    console.log("Received values:");
  };
  const [form] = Form.useForm();

  useEffect(() => {
    form.setFieldsValue({
      fullName: props?.user?.fullName,
      userName: props?.user?.userName,
      gender: props?.user?.gender,
      address: props?.user?.address,
      dob: props?.user ? dayjs(props?.user?.dob, "YYYY/MM/DD") : null,
      bloodGroup: props?.user?.bloodGroup,
    });
  }, []);

  const submit = async (values: any) => {
    console.log("Submit", values);
    let obj = {
      fullName: values.fullName,
      userName: values.userName,
      gender: values.gender,

      address: values.address,
      bloodGroup: values.bloodGroup,
      photo: String(file) ? String(file) : props.user.photo,
    };
    setIsLoading(true);
    try {
      await props.update(obj);
    } finally {
      setIsLoading(false);
    }
  };

  const UploadFile = async (data: any) => {
    try {
      console.log(data);
      const myFile = data.file;
      let file = myFile.originFileObj;
      const name = moment(new Date()).unix();
      const myNewFile = new File([file], name + ".png", {
        type: myFile.type,
      });
      const url = URL.createObjectURL(myNewFile);
      let _upload = await FILE_UPLOAD(myNewFile);
      console.log("upload --> ", _upload);
      setFile(_upload);
    } catch (err) {
      console.log("UploadFile = = = >", err);
    }
  };

  return (
    <Container>
      <h1>{props.data?.accountName}</h1>
      <Form form={form} onFinish={submit}>
        <Card>
          <Row>
            <Col md="6">
              <img
                style={{
                  width: "200px",
                  height: "200px",
                  borderRadius: "100%",
                  marginBottom: "30px",
                }}
                src={props?.user?.photo}
              />
              <Form.Item name={"file"} rules={[{ required: false }]}>
                <Upload onChange={(data) => UploadFile(data)}>
                  <div
                    style={{
                      marginLeft: 10,
                      width: "100%",
                      cursor: "pointer",
                    }}
                  >
                    <EditOutlined /> Edit Photo
                  </div>
                </Upload>
              </Form.Item>
            </Col>

            <Col md="6" />
            <br />
            <br />
            <Col md="6">
              <label className="formLabel">Name</label>
              <Form.Item
                name="fullName"
                style={{ marginBottom: 10 }}
                rules={[{ required: true }]}
              >
                <Input size="large" placeholder="" />
              </Form.Item>
            </Col>
            <Col md="6">
              <label className="formLabel">Gender</label>
              <Form.Item
                name="gender"
                style={{ marginBottom: 10 }}
                rules={[{ required: true }]}
              >
                <Select placeholder="Select Gender" size="large">
                  <Select.Option value="female">Female</Select.Option>
                  <Select.Option value="male">Male</Select.Option>
                </Select>
              </Form.Item>
            </Col>

            <Col md="6">
              <label className="formLabel">User Name</label>
              <Form.Item
                name="userName"
                style={{ marginBottom: 10 }}
                rules={[{ required: true }]}
              >
                <Input size="large" placeholder="" />
              </Form.Item>
            </Col>
            <Col md="6">
              <label className="formLabel">DOB</label>
              <Form.Item
                name="dob"
                style={{ marginBottom: 10 }}
                rules={[{ required: true }]}
              >
                <DatePicker
                  placeholder=""
                  size="large"
                  style={{ width: "100%" }}
                />
              </Form.Item>
            </Col>

            <Col md="6">
              <label className="formLabel">Blood Group</label>
              <Form.Item
                name="bloodGroup"
                style={{ marginBottom: 10 }}
                rules={[{ required: true }]}
              >
                <Input size="large" placeholder="" />
              </Form.Item>
            </Col>

            <Col md="6">
              <label className="formLabel">Address</label>
              <Form.Item name="address" rules={[{ required: true }]}>
                <Input.TextArea size="large" placeholder="" rows={4} />
              </Form.Item>
            </Col>
          </Row>
          <br />
          <Row>
            <Col md="6"></Col>
            <Col md="3">
              <Form.Item>
                <Button size="large" style={{ width: "100%" }}>
                  Cancel
                </Button>
              </Form.Item>
            </Col>
            <Col md="3">
              <Form.Item>
                <Button
                  size="large"
                  style={{ width: "100%" }}
                  type="primary"
                  htmlType="submit"
                  loading={isLoading}
                >
                  Update
                </Button>
              </Form.Item>
            </Col>
          </Row>
        </Card>
      </Form>
    </Container>
  );
}

export default PersonalDetails;
