import React, { useEffect, useState } from "react";
import {
  Button,
  DatePicker,
  Form,
  Input,
  Select,
  Table,
  Card,
  notification,
  Pagination,
  Spin,
} from "antd";
import { GET, POST } from "../../utils/apiCalls";
import { API } from "../../config/api";
import { useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import DataTable from "./Table";
import { Col, Container, Row } from "react-bootstrap";
import form from "antd/es/form";
import dayjs from "dayjs";
import PageHeader from "../pageheader/pageheader";
import { Moment } from "moment";
import moment from "moment";
import { LoadingOutlined } from "@ant-design/icons";

const Timesheet: React.FC = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [TimesheetData, setTimesheetData] = useState([]);
  // const [selectedDate, setSelectedDate] = useState(new Date());
  const [MemberData, setMemberData] = useState([]);
  const [Taskdata, setTaskData] = useState([]);
  const [form] = Form.useForm();
  const [selectedDate, setSelectedDate] = useState<Moment | null>(null);
  const User = useSelector((state: any) => state.User.user);
  const [page, setPage] = useState(1); // State for current page
  const [meta, setMeta] = useState<any>({});
  // const onFinish = (values: any) => {
  //   console.log("Received values:", values);
  //   handleSubmit(values);
  // };

  const reload = () => {
    fetchHrTimesheet(1, 10);
  };

  const handleDateChange = (date: Moment | null) => {
    setSelectedDate(date);
  };

  const convertToIndianTimezone = (date: any) => {
    // if (!date) return "";
    // return moment.tz(date, "Asia/Kolkata").format("YYYY-MM-DD HH:mm:ss");
    return date;
  };

  const fetchHrProject = async (values: any) => {
    try {
      setIsLoading(true);

      let obj = {
        userId: Number(User.id),
      };
      console.log(obj);
      const url = API.GET_USERID_FILTER + User.id;
      const members: any = await GET(url, obj);
      console.log("qwerty", members);
      if (members.status) {
        setMemberData(members?.data);
      } else {
        setMemberData([]);
      }
    } catch (error) {
      console.log(error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchHrProject(User.id);
  }, []);

  useEffect(() => {
    if (User && User.id) {
      // getTaskData(User.id);
    }
  }, [User]);

  const getTaskData = async (proId: number) => {
    try {
      setIsLoading(true);
      const takeValue = Math.min(100, 10);
      let objs = {
        projectId: Number(proId),
        assignedTo: User.id,
      };
      console.log("dataaaaaaaaaa", objs);
      let api = API.GET_FILTER_TASK + `?order=ASC&page=1&take=${takeValue}`;
      const data: any = await POST(api, objs);

      if (data?.data) {
        setTaskData(data?.data);
      } else {
        console.error("Data is not available:", data);
      }
    } catch (error) {
      console.log(error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleReset = () => {
    form.resetFields();
  };

  const getHoursTextColor = (hours: number) => {
    if (hours < 8) return "blue";
    if (hours === 8) return "green";
    return "red";
  };

  const columns = [
    {
      title: "Project",
      dataIndex: "title",
      key: "title",
      render: (_: any, record: any) => <span>{record?.project?.title}</span>,
    },

    {
      title: "Task",
      dataIndex: "title",
      key: "title",
      render: (_: any, record: any) => <span>{record?.task?.title}</span>,
    },
    { dataIndex: "description", title: "Description" },
    { dataIndex: "remark", title: "Remark" },
    {
      dataIndex: "hours",
      title: "Hours",
      render: (hours: number) => (
        <span
          style={{
            color: getHoursTextColor(hours),
            padding: "5px",
            borderRadius: "4px",
          }}
        >
          {hours}
        </span>
      ),
    },
    { dataIndex: "minutes", title: "Minutes" },
    {
      title: "Date",
      dataIndex: "Date",
      key: "Date",
      render: (Date: any) => <span>{moment(Date).format("DD-MM-YYYY")}</span>,
    },
  ];

  // const handleDateChange = (date: any) => {
  //   setSelectedDate(date.toDate());
  // };
  const handleSubmit = async (values: any) => {
    try {
      console.log("values ---> ", values);

      const obj = {
        projectId: Number(values.project),
        taskId: Number(values.task),
        description: values.description,
        remark: values.Remark,
        hours: Number(values.hours),
        minutes: values.minutes ? Number(values.minutes) : 0,
        employeeId: Number(User.id),

        Date: values.Date.format("YYYY-MM-DD"),
        status: true,
      };

      console.log("time", obj);
      let api = API.POST_TIMESHEET;
      let postRequest: any = await POST(api, obj);
      console.log("dattataaaa", postRequest);
      if (postRequest.status) {
        notification.success({ message: "Timesheet submitted successfully" });
      } else {
        notification.error({ message: "Something went wrong" });
      }
      reload();
    } catch (error) {
      console.log(error);
      notification.error({
        message: "Something! Please try again later",
      });
    }
  };

  const fetchHrTimesheet = async (page: any, take: any) => {
    try {
      setIsLoading(true);
      let obj = {
        employeeId: User.id,
        query: form.getFieldValue("query"),
        page: page,
        take: 10,
      };
      const url = API.POST_PAGE_TIMESHEET;
      const response: any = await POST(url, obj);
      setTimesheetData(response?.data);
      setMeta(response?.meta);
    } catch (error) {
      console.log(error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchHrTimesheet(1, 10);
  }, []);

  const fetchDateTimesheet = async (date: Date) => {
    try {
      setIsLoading(true);
      const takeValue = Math.min(100, 10);
      const api = `${API.GET_TIMESHEET_FILTER}?order=ASC&page=1&take=${takeValue}`;

      const obj = {
        date: date.toISOString(),
        employeeId: User.id,
      };

      const { data }: any = await POST(api, obj);
      setTimesheetData(data);
    } catch (error) {
      console.log(error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (selectedDate) {
      fetchDateTimesheet(selectedDate.toDate());
    }
  }, [selectedDate]);

  return (
    <div className="screen-box">
      <PageHeader title="TIMESHEET DEATILS" />
      <br />
      <Container>
        <Card>
          <Form onFinish={handleSubmit} form={form} layout="vertical">
            <Row gutter={9}>
              <Col md={4}>
                <Form.Item
                  label="Project"
                  name="project"
                  rules={[
                    { required: true, message: "Please select a project" },
                  ]}
                >
                  <Select
                    size="large"
                    showSearch
                    placeholder="Select Project"
                    allowClear
                    onSelect={(val: any) => getTaskData(val)}
                  >
                    {MemberData &&
                      MemberData.map((project: any) => (
                        <Select.Option
                          key={project.project.id}
                          value={project.project.id}
                        >
                          {project.project.title}
                        </Select.Option>
                      ))}
                  </Select>
                </Form.Item>
              </Col>

              <Col md={4}>
                <Form.Item
                  label="Task"
                  name="task"
                  rules={[{ required: true, message: "Please select a task" }]}
                >
                  <Select
                    placeholder="Select Task"
                    allowClear
                    showSearch
                    size="large"
                  >
                    {Taskdata &&
                      Taskdata?.map((project: any) => (
                        <Select.Option key={project?.id} value={project?.id}>
                          {project?.title}
                        </Select.Option>
                      ))}
                  </Select>
                </Form.Item>
              </Col>

              <Col md={2}>
                <Form.Item
                  label="Hours"
                  name="hours"
                  rules={[{ required: true, message: "Please input hours" }]}
                >
                  <Input size="large" type="number" min={0} max={24} />
                </Form.Item>
              </Col>
              <Col md={2}>
                <Form.Item
                  label="Minutes (Optional)"
                  name="minutes"
                  rules={[{ required: false, message: "Please input minutes" }]}
                >
                  <Input type="number" min={0} max={59} />
                </Form.Item>
              </Col>
            </Row>

            <Row gutter={16}>
              <Col md={4}>
                <Form.Item
                  label="Description"
                  name="description"
                  rules={[
                    { required: true, message: "Please input a description" },
                  ]}
                >
                  <Input.TextArea size="large" rows={3} />
                </Form.Item>
              </Col>

              <Col md={4}>
                <Form.Item
                  label="Remark"
                  name="Remark"
                  rules={[{ required: true, message: "Please input a remark" }]}
                >
                  <Input.TextArea rows={3} size="large" />
                </Form.Item>
              </Col>

              <Col md={4}>
                <Form.Item
                  label="Date"
                  name="Date"
                  rules={[
                    {
                      required: true,
                      message: "Please select a date",
                    },
                  ]}
                >
                  <DatePicker size="large" style={{ width: "100%" }} />
                </Form.Item>
              </Col>
            </Row>

            <Row gutter={5}>
              <Col md={7} />
              <Col md={2}>
                <Form.Item>
                  <Button
                    size="large"
                    style={{ width: "100%" }}
                    onClick={handleReset}
                  >
                    Reset
                  </Button>
                </Form.Item>
              </Col>

              <Col md={3}>
                <Form.Item>
                  <Button
                    size="large"
                    style={{ width: "100%" }}
                    type="primary"
                    htmlType="submit"
                    disabled={isLoading}
                  >
                    {isLoading ? (
                      <Spin
                        indicator={
                          <LoadingOutlined
                            style={{ fontSize: 24, color: "#fff" }}
                            spin
                          />
                        }
                      />
                    ) : (
                      "Submit"
                    )}
                  </Button>
                </Form.Item>
              </Col>
            </Row>
          </Form>
        </Card>
        <br />
      </Container>
      <Container>
        <Card>
          <h5>Timesheet Data</h5>
          <br />
          <h6 className="">Total {meta.total_count} data</h6>

          <br />
          <Row>
            <Col md={4}>
              <Form.Item name="Date">
                <DatePicker
                  size="large"
                  style={{ width: "100%" }}
                  onChange={handleDateChange}
                />
              </Form.Item>
            </Col>
            <Col md={1}>
              <Button
                size="large"
                onClick={() =>
                  selectedDate && fetchDateTimesheet(selectedDate.toDate())
                }
              >
                Search
              </Button>
            </Col>
          </Row>

          <Table
            columns={columns}
            pagination={false}
            dataSource={TimesheetData}
          />
          <div
            style={{
              display: "flex",
              justifyContent: "flex-end",
              marginTop: "20px",
            }}
          >
            {meta?.total_count > meta?.take && (
              <Pagination
                defaultCurrent={meta?.page}
                total={meta?.total_count}
                pageSize={meta?.take}
                onChange={(page) => fetchHrTimesheet(page, meta?.take)}
              />
            )}
          </div>
        </Card>
      </Container>
    </div>
  );
};

export default Timesheet;
