// import React from "react";

// function TaskView() {
//   return <div>TaskView</div>;
// }

// export default TaskView;

import React, { useEffect, useState } from "react";
import { useLocation, useParams } from "react-router-dom";

import {
  Button,
  Card,
  Col,
  Form,
  Pagination,
  Row,
  Select,
  Tag,
  notification,
} from "antd";

import { Container, Table } from "react-bootstrap";

import { useNavigate } from "react-router-dom";

import { useForm } from "antd/es/form/Form";

import { useSelector } from "react-redux";
import { GET, POST } from "../../utils/apiCalls";
import { API } from "../../config/api";
import TextArea from "antd/es/input/TextArea";
import CommentsDataTable from "./commentsdatatable";
import moment from "moment";
import PageHeader from "../pageheader/pageheader";

function ViewHrTask() {
  const [isLoading, setIsLoading] = useState(false);
  const [Taskdata, setTaskData] = useState<any>([]);
  const [Comments, setComments] = useState<any>([]);
  const { id } = useParams();
  const [TaskId, setTaskId] = useState<number | null>(null);
  const [commentValue, setCommentValue] = useState("");
  const [page, setPage] = useState(1);
  const [meta, setMeta] = useState<any>({});
  const [commentsData, setCommentsData] = useState<any>([]);
  const [take, setTake] = useState(10);
  const [taskHistoryData, setTaskHistoryData] = useState<any>([]);
  const User = useSelector((state: any) => state.User.user);
  const [form] = Form.useForm();
  const isTodayOrPast = moment().isSameOrBefore(moment(), "day");
  const navigate = useNavigate();

  const onFinish = async (values: any) => {
    try {
      setIsLoading(true);

      let obj = {
        commentId: "hello",
        text: values.commentValue,
        created_by: Number(User.id),
        task: Number(id),
        parentId: 4,
        status: true,
      };

      let api = API.COMMENTS;
      let postRequest = await POST(api, obj);
      if (postRequest) {
        notification.success({ message: "Comment created successfully" });
        form.resetFields();
        getAllTaskData();
        getComments(page, 5);
        // notification.error({ message: "Something went wrong" });
      }
    } catch (error) {
      console.log(error);
      notification.error({
        message: "Something went wrong! Please try again later",
      });
    } finally {
      setIsLoading(false);
    }
  };

  console.log("taskHistoryData ", taskHistoryData);

  // const getComments = async (page: any, take: any) => {
  //   try {
  //     let api = `${API.COMMENTS}?order=DESC&page=${page}&take=${take}&userId=${UserId}`;
  //     const Data: any = await GET(api, {});
  //     console.log("comments data", Data);
  //     if (Data?.status) {
  //       setComments(Data?.data);

  //       setTaskId(Data?.data?.id);
  //     } else {
  //       setComments({});
  //     }
  //   } catch (error) {
  //     console.log(error);
  //   }
  // };

  const getComments = async (page: any, take: any) => {
    try {
      setIsLoading(true);

      let objs = {
        task: Number(Taskdata.id),
        created_by: User.id,
        query: form.getFieldValue("query"),
        page: page,
        take: take,
      };
      let api = API.POST_PAGE_COMMENTS;
      const data: any = await POST(api, objs);

      if (data?.data) {
        setComments(data?.data);
        setMeta(data?.meta);
      } else {
        console.error("Data is not available:", data);
      }
    } catch (error) {
      console.log(error);
    } finally {
      setIsLoading(false);
    }
  };

  const getAllTaskData = async () => {
    try {
      let api = API.GET_TASK + id;
      const Data: any = await GET(api, {});
      console.log("task data", Data);
      if (Data?.status) {
        setTaskData(Data?.data);
        setTaskId(Data?.data?.id);
      } else {
        setTaskData({});
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getAllTaskData();
    if (User && User.id) {
      getComments(page, 100);
    }
  }, [page, User]);

  return (
    <>
      <>
        <PageHeader
          title="TASK VIEW"
          onPageChange={() => navigate(`/Auth/project/task-details/`)}
        />
        <br />
        <br />
        <br />

        <Container>
          <Card>
            <h4 className="productAdd-Txt1">Task Details</h4>
            <Row gutter={[16, 16]}>
              <Col span={6}>
                <strong>title :</strong>
              </Col>
              <Col span={18}>
                <span>{Taskdata?.title}</span>
              </Col>
            </Row>

            <Row gutter={[16, 16]}>
              <Col span={6}>
                <strong>Description :</strong>
              </Col>
              <Col span={18}>
                <span>{Taskdata?.description}</span>
              </Col>
            </Row>
            <Row gutter={[16, 16]}>
              <Col span={6}>
                <strong>Priority :</strong>
              </Col>
              <Col span={18}>
                <span>{Taskdata?.priority}</span>
              </Col>
            </Row>
            <Row gutter={[16, 16]}>
              <Col span={6}>
                <strong>Project Id:</strong>
              </Col>
              <Col span={18}>
                <span>{Taskdata?.project?.title}</span>
              </Col>
            </Row>

            <Row gutter={[16, 16]}>
              <Col span={6}>
                <strong>Start Date :</strong>
              </Col>
              <Col span={18}>
                <span> {moment(Taskdata?.startDate).format("ll")}</span>
              </Col>
            </Row>
            <Row gutter={[16, 16]}>
              <Col span={6}>
                <strong>End Date :</strong>
              </Col>
              {/* <Col span={18}>
                <span>{Taskdata?.endDate}</span>
              </Col> */}
              <Col span={18}>
                <span style={{ color: isTodayOrPast ? "red" : "black" }}>
                  {moment(Taskdata?.endDate).format("ll")}
                </span>
              </Col>
            </Row>
            {/* <Row gutter={[16, 16]}> */}
            {/* <Col span={6}>
                <strong>Created By :</strong>
              </Col> */}
            {/* <Col span={18}>
                <span>{Taskdata?.createdBy}</span>
              </Col>
            </Row> */}
            <Row gutter={[16, 16]}>
              <Col span={6}>
                <strong>status :</strong>
              </Col>
              {/* <Col span={18}>
                <span>{Taskdata?.status}</span>
              </Col> */}
              <Col span={18}>
                <Tag
                  color={
                    Taskdata?.status === "pending"
                      ? "red"
                      : Taskdata?.status === "in_progress"
                      ? "blue"
                      : "green"
                  }
                  key={Taskdata?.status}
                >
                  <a>{Taskdata?.status?.toUpperCase()}</a>
                </Tag>
              </Col>
            </Row>
            <Row gutter={[16, 16]}>
              <Col span={6}>
                <strong>Assigned To:</strong>
              </Col>
              <Col span={18}>
                <span>{Taskdata?.empinfo?.fullName}</span>
              </Col>
            </Row>
          </Card>
        </Container>

        <Card>
          <Form form={form} onFinish={onFinish}>
            <Form.Item
              name="commentValue"
              rules={[{ required: true, message: "Input Your Comments!" }]}
            >
              <TextArea />
            </Form.Item>
            <Button type="primary" htmlType="submit">
              Add Comment
            </Button>
          </Form>
          {/* <CommentsDataTable dataSource={Comments} /> */}
          <CommentsDataTable
            dataSource={Comments}
            isLoading={isLoading}
            meta={meta}
            onPageChange={(page: number, pageSize: number) => {
              setIsLoading(true);
              setPage(page);
              setTake(pageSize);
            }}
          />
          {/* <Card>
            <h4 className="productAdd-Txt1">Task History</h4>
            {taskHistoryData?.map((data: any) => {
              return (
                <>
                
                  <Row gutter={[16, 16]}>
                    <Col span={6}>
                      <strong>Name:</strong>
                    </Col>
                    <Col span={18}>
                      <span>{data?.employee?.fullName}</span>
                    </Col>
                  </Row>

                  <Row gutter={[16, 16]}>
                    <Col span={6}>
                      <strong>Status:</strong>
                    </Col>
                    <Col span={18}>
                      <Tag
                        color={
                          data?.status === "pending"
                            ? "red"
                            : data?.status === "in_progress"
                            ? "blue"
                            : "green"
                        }
                        key={data?.status}
                      >
                        <a>{data?.status?.toUpperCase()}</a>
                      </Tag>
                    </Col>
                  </Row>
                  <Row gutter={[16, 16]}>
                    <Col span={6}>
                      <strong>Created At:</strong>
                    </Col>
                    <Col span={18}>
                      <span>{moment(data.createdAt).format("lll")}</span>
                    </Col>
                  </Row>
                </>
              );
            })}
          </Card> */}
        </Card>
      </>
    </>
  );
}

export default ViewHrTask;
