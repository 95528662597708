// import {
//   Button,
//   Card,
//   DatePicker,
//   Form,
//   Input,
//   Select,
//   Upload,
//   notification,
// } from "antd";
// import form from "antd/es/form";
// import React, { useEffect, useState } from "react";
// import { Col, Container, Row } from "react-bootstrap";
// import dayjs from "dayjs";

// import moment from "moment";
// import { FILE_UPLOAD, GET, PUT } from "../../../utils/apiCalls";
// import {
//   EditOutlined,
//   EyeInvisibleOutlined,
//   EyeOutlined,
// } from "@ant-design/icons";
// import { API } from "../../../config/api";
// import { useSelector } from "react-redux";

// function UpdatePassword(props: any) {
//   const [file, setFile] = useState<any>([]);
//   const [upload, setUpload] = useState("");
//   const User = useSelector((state: any) => state.User.user);
//   const [isLoading, setIsLoading] = useState(false);
//   const [userData, setUserData] = useState(null);
//   console.log(props.data);
//   const onFinish = () => {
//     console.log("Received values:");
//   };
//   const [form] = Form.useForm();

//   const submitHandler = async (values: any) => {
//     try {
//       let obj = {
//         currentPassword: values.currentPassword,
//         newPassword: values.newPassword,
//         confirmPassword: values.confirmPassword,
//       };
//       console.log("obj-----", obj);
//       setIsLoading(true);
//       let api = API.PASSWORD_UPDATE + User.id;
//       let postRequest: any = await PUT(api, obj);
//       if (postRequest.status) {
//         notification.success({ message: "Employeee Updated" });
//       } else {
//         notification.error({ message: "something went wrong" });
//       }
//     } catch (error) {
//       console.log(error);
//       notification.error({
//         message: "Something went wrong! Please try again later",
//       });
//     } finally {
//       setIsLoading(false);
//     }
//   };

//   return (
//     <Container>
//       <h1>{props.data?.accountName}</h1>
//       <Form form={form} onFinish={submitHandler}>
//         <Card>
//           <Col md="6">
//             <label className="formLabel">Current Password</label>
//             <Form.Item
//               name="currentPassword"
//               style={{ marginBottom: 10 }}
//               rules={[{ required: true }]}
//             >
//               <Input.Password
//                 size="large"
//                 placeholder=""
//                 iconRender={(visible) =>
//                   visible ? <EyeOutlined /> : <EyeInvisibleOutlined />
//                 }
//               />
//             </Form.Item>
//           </Col>

//           <Col md="6">
//             <label className="formLabel">New Password</label>
//             <Form.Item
//               name="newPassword"
//               style={{ marginBottom: 10 }}
//               rules={[{ required: true }]}
//             >
//               <Input.Password
//                 size="large"
//                 placeholder=""
//                 iconRender={(visible) =>
//                   visible ? <EyeOutlined /> : <EyeInvisibleOutlined />
//                 }
//               />
//             </Form.Item>
//           </Col>
//           <Col md="6">
//             <label className="formLabel">Confirm Password</label>
//             <Form.Item
//               name="confirmPassword"
//               style={{ marginBottom: 10 }}
//               rules={[{ required: true }]}
//             >
//               <Input.Password
//                 size="large"
//                 placeholder=""
//                 iconRender={(visible) =>
//                   visible ? <EyeOutlined /> : <EyeInvisibleOutlined />
//                 }
//               />
//             </Form.Item>
//           </Col>

//           <br />
//           <Row>
//             <Col md="6"></Col>
//             <Col md="3">
//               <Form.Item>
//                 <Button size="large" style={{ width: "100%" }}>
//                   Cancel
//                 </Button>
//               </Form.Item>
//             </Col>
//             <Col md="3">
//               <Form.Item>
//                 <Button
//                   size="large"
//                   style={{ width: "100%" }}
//                   type="primary"
//                   htmlType="submit"
//                   loading={isLoading}
//                 >
//                   Update
//                 </Button>
//               </Form.Item>
//             </Col>
//           </Row>
//         </Card>
//       </Form>
//     </Container>
//   );
// }

// export default UpdatePassword;

import { Button, Card, Form, Input, notification } from "antd";
import React, { useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { EyeInvisibleOutlined, EyeOutlined } from "@ant-design/icons";
import { API } from "../../../config/api";
import { useSelector } from "react-redux";
import { PUT } from "../../../utils/apiCalls";

function UpdatePassword(props: any) {
  const [isLoading, setIsLoading] = useState(false);
  const User = useSelector((state: any) => state.User.user);

  const [form] = Form.useForm();

  const submitHandler = async (values: any) => {
    try {
      let obj = {
        currentPassword: values.currentPassword,
        newPassword: values.newPassword,
        confirmPassword: values.confirmPassword,
      };
      console.log("obj-----", obj);
      setIsLoading(true);
      let api = API.PASSWORD_UPDATE + User.id;
      let postRequest: any = await PUT(api, obj);
      if (postRequest.status) {
        notification.success({ message: "Password Updated" });
      } else {
        notification.error({ message: "Something went wrong" });
      }
    } catch (error) {
      console.log(error);
      notification.error({
        message: "Something went wrong! Please try again later",
      });
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Container>
      <h1>{props.data?.accountName}</h1>
      <Form form={form} onFinish={submitHandler}>
        <Card>
          <Col md="6">
            <label className="formLabel">Current Password</label>
            <Form.Item
              name="currentPassword"
              style={{ marginBottom: 10 }}
              rules={[
                {
                  required: true,
                  message: "Please input your current password!",
                },
              ]}
            >
              <Input.Password
                size="large"
                placeholder=""
                iconRender={(visible) =>
                  visible ? <EyeOutlined /> : <EyeInvisibleOutlined />
                }
              />
            </Form.Item>
          </Col>

          <Col md="6">
            <label className="formLabel">New Password</label>
            <Form.Item
              name="newPassword"
              style={{ marginBottom: 10 }}
              rules={[
                { required: true, message: "Please input your new password!" },
              ]}
            >
              <Input.Password
                size="large"
                placeholder=""
                iconRender={(visible) =>
                  visible ? <EyeOutlined /> : <EyeInvisibleOutlined />
                }
              />
            </Form.Item>
          </Col>

          <Col md="6">
            <label className="formLabel">Confirm Password</label>
            <Form.Item
              name="confirmPassword"
              style={{ marginBottom: 10 }}
              dependencies={["newPassword"]}
              hasFeedback
              rules={[
                {
                  required: true,
                  message: "Please confirm your new password!",
                },
                ({ getFieldValue }) => ({
                  validator(_, value) {
                    if (!value || getFieldValue("newPassword") === value) {
                      return Promise.resolve();
                    }
                    return Promise.reject(
                      new Error("The two passwords do not match!")
                    );
                  },
                }),
              ]}
            >
              <Input.Password
                size="large"
                placeholder=""
                iconRender={(visible) =>
                  visible ? <EyeOutlined /> : <EyeInvisibleOutlined />
                }
              />
            </Form.Item>
          </Col>

          <br />
          <Row>
            <Col md="6"></Col>
            <Col md="3">
              <Form.Item>
                <Button size="large" style={{ width: "100%" }}>
                  Cancel
                </Button>
              </Form.Item>
            </Col>
            <Col md="3">
              <Form.Item>
                <Button
                  size="large"
                  style={{ width: "100%" }}
                  type="primary"
                  htmlType="submit"
                  loading={isLoading}
                >
                  Update
                </Button>
              </Form.Item>
            </Col>
          </Row>
        </Card>
      </Form>
    </Container>
  );
}

export default UpdatePassword;
