// import React from "react";
// import { Card, Tag } from "antd";
// import { Container } from "react-bootstrap";

// const CommentsDataTable = (props: any) => {
//   console.log("props==>", props);

//   return (
//     <Container>
//       <br />
//       {props?.dataSource?.map((comment: any, index: any) => (
//         <Card key={index} style={{ marginBottom: "20px" }}>
//           <p>
//             <strong>Comments:</strong>
//             {comment.text}
//           </p>
//           <p>
//             <strong>Created By:</strong> {comment?.employee?.fullName}
//           </p>
//           {/* <p><strong>Task:</strong> {comment.task}</p> */}
//         </Card>
//       ))}
//     </Container>
//   );
// };

// export default CommentsDataTable;
import React from "react";
import { Container } from "react-bootstrap";
import "./style.scss";
import moment from "moment";
import { Avatar, Pagination } from "antd";

const CommentsDataTable = (props: any) => {
  console.log("props==>", props);

  function getRandomColor() {
    // Generate a random hex color code
    return "#" + Math.floor(Math.random() * 16777215).toString(16);
  }

  return (
    <Container>
      <br />
      {props?.dataSource?.map((comment: any, index: any) => (
        <div className="comment-item" key={index}>
          <Avatar style={{ backgroundColor: getRandomColor() }}>
            {comment?.employee?.fullName.slice(0, 1)}
          </Avatar>
          <div
            className=""
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              width: "100%",
            }}
          >
            <div className="comment-text">{comment.text}</div>
            <div className="comment-text2">
              {moment(comment.createdAt).format("lll")}
            </div>
          </div>
          {/* <Pagination
            total={props?.meta?.total_count}
            pageSize={props?.meta?.take}
            showTotal={(total) => `Total ${total} comments`}
            onChange={(page, pageSize) => props?.onPageChange(page, pageSize)}
            style={{ textAlign: "center", marginTop: "20px" }}
          /> */}
        </div>
      ))}
    </Container>
  );
};

export default CommentsDataTable;
