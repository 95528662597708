// import { Card, Space, Table, TableProps, Tag } from "antd";
// import moment from "moment";
// import React, { useEffect, useRef, useState } from "react";
// import { Container } from "react-bootstrap";
// import { MdRemoveRedEye } from "react-icons/md";
// import { useNavigate } from "react-router-dom";
// import { GET } from "../../utils/apiCalls";
// import { API } from "../../config/api";

// const DataTable = ({ dataSource }: any) => {
//   const dataGridRef: any = useRef(null);
//   const [selectedRows, setSelectedRows] = useState();
//   const [page, SetPage] = useState(1);
//   const [take, setTake] = useState(10);
//   const navigate = useNavigate();
//   interface DataType {
//     key: string;
//     name: string;
//     tags: string[];
//   }

//   const columns: TableProps<DataType>["columns"] = [
//     {
//       title: "Task ID",
//       dataIndex: "taskId",
//       key: "taskId",
//     },
//     {
//       title: "Title",
//       dataIndex: "title",
//       key: "title",
//     },
//     {
//       title: "Description",
//       dataIndex: "description",
//       key: "description",
//     },
//     {
//       title: "Type",
//       dataIndex: "type",
//       key: "type",
//     },
//     {
//       title: "Priority",
//       dataIndex: "priority",
//       key: "priority",
//     },
//     {
//       title: "Project ID",
//       dataIndex: "projectId",
//       key: "projectId",
//     },
//     {
//       title: "Status",
//       dataIndex: "status",
//       key: "status",
//     },
//     {
//       title: "Created By",
//       dataIndex: "createdBy",
//       key: "createdBy",
//     },
//     {
//       title: "Assigned To",
//       dataIndex: "assignedTo",
//       key: "assignedTo",
//     },
//     {
//       title: "Start Date",
//       dataIndex: "startDate",
//       key: "startDate",
//     },
//     {
//       title: "End Date",
//       dataIndex: "endDate",
//       key: "endDate",
//     },
//     {
//       title: "Tag",

//       dataIndex: "tag",
//       key: "tag",
//     },
//     {
//       title: "Label",
//       dataIndex: "label",
//       key: "label",
//     },
//   ];

//   const [isLoading, setIsLoading] = useState(false);

//   const [Taskdata, setTaskData] = useState<any>([]);

//   useEffect(() => {
//     getDueTaskData();
//   }, []);

//   const getDueTaskData = async () => {
//     try {
//       setIsLoading(true);
//       let api = API.GET_TASKCOUNT_TASK;
//       const data: any = await GET(api, {});
//       setTaskData(data);
//       console.log(data);
//     } catch (error) {
//       console.log(error);
//     } finally {
//       setIsLoading(false);
//     }
//   };
//   console.log("data", Taskdata);
//   return (
//     <Container>
//       <br />
//       <Card>
//         <Table columns={columns} dataSource={Taskdata} />
//       </Card>
//     </Container>
//   );
// };
// export default DataTable;

// DataTable.js
import { Card, Pagination, Space, Spin, Table, Tag } from "antd";
import moment from "moment";
import React, { useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { API } from "../../config/api";
import { POST, PUT } from "../../utils/apiCalls";
import { MdRemoveRedEye } from "react-icons/md";

const DataTable = (props: any) => {
  const { id } = useParams();
  console.log("dataSource --> ", props?.dataSource);
  const [TaskStatus, setTaskStatus] = useState("");
  const [loading, setLoading] = useState(false);
  const User = useSelector((state: any) => state.User.user);
  const navigate = useNavigate();

  const updateStatus = async (record: any, newStatus: string) => {
    try {
      setLoading(true);
      console.log("Updating task status:", record.id, newStatus);

      const updatedTask = {
        ...record,
        status: newStatus,
        isStatusUpdate: true,
      };

      const api = API.PUT_TASK + record.id;
      const response: any = await PUT(api, updatedTask);
      console.log("Response from Server:", response);

      if (response) {
        setTaskStatus(response.data);
        props?.reload();
      }
    } catch (error) {
      console.error("Error updating task status:", error);
    } finally {
      setLoading(false);
    }
  };

  const columns = [
    {
      title: "ID",
      dataIndex: "id",
    },
    {
      title: "Title",
      dataIndex: "title",
    },
    {
      title: "Description",
      dataIndex: "description",
    },
    {
      title: "Type",
      dataIndex: "type",
    },
    {
      title: "Priority",
      dataIndex: "priority",
    },
    {
      title: "Status",
      dataIndex: "status",
      render: (text: any, record: any) => (
        <select
          value={record.status}
          onChange={(e) => updateStatus(record, e.target.value)}
        >
          <option value="pending">Pending</option>
          <option value="in_progress">In Progress</option>
          <option value="completed">Completed</option>
        </select>
      ),
    },
    // {
    //   title: "Status",
    //   dataIndex: "status",
    //   key: "status",
    //   render: (text: any) => (
    //     <>
    //       <Tag
    //         color={
    //           text === "pending"
    //             ? "red"
    //             : text === "in_progress"
    //             ? "blue"
    //             : "green"
    //         }
    //         key={text}
    //       >
    //         <a>{text?.toUpperCase()}</a>
    //       </Tag>
    //     </>
    //   ),
    // },
    // {
    //   title: "Created By",
    //   dataIndex: "createdBy",
    // },
    {
      title: "Start Date",
      dataIndex: "startDate",
      render: (_: any, record: any) => (
        <span>{moment(record.startDate).format("YYYY-MM-DD")}</span>
      ),
    },
    {
      title: "End Date",
      dataIndex: "endDate",
      key: "endDate",
      render: (text: any) => {
        const isTodayOrPast = moment(text).isSameOrBefore(moment(), "day");
        return (
          <a style={{ color: isTodayOrPast ? "red" : "black" }}>
            {moment(text).format("ll")}
          </a>
        );
      },
    },
    {
      title: "Action",
      key: "id",
      render: (_: any, record: any) => (
        <Space size="middle">
          <MdRemoveRedEye
            color="blue"
            size={20}
            onClick={() => navigate(`/Auth/project/taskView/${record?.id}`)}
          />
        </Space>
      ),
    },
  ];

  return (
    <>
      <br />
      <Card>
        <h6>Total {props?.meta?.total_count} Tasks</h6>
        {loading ? (
          <>
            <Spin size="large" />
          </>
        ) : (
          <Table
            columns={columns}
            dataSource={props?.dataSource}
            pagination={false}
          />
        )}
      </Card>
      <Pagination
        total={props?.meta?.total_count}
        pageSize={props?.meta?.take}
        showTotal={(total) => `Total ${total} Task`}
        onChange={(page, pageSize) => props?.onPageChange(page, pageSize)}
        style={{
          display: props.meta.total_count > 10 ? "block" : "none",
          textAlign: "right",
          marginTop: "20px",
        }}
      />
    </>
  );
};

export default DataTable;
