import {
  EditOutlined,
  LoadingOutlined,
  UploadOutlined,
} from "@ant-design/icons";
import { Button, Card, Form, Input, Select, Spin, Upload } from "antd";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { FILE_UPLOAD } from "../../../utils/apiCalls";

function IdProof(props: any) {
  const [file, setFile] = useState<any>([]);
  const [isLoading, setIsLoading] = useState(false);
  const onFinish = (values: any) => {
    console.log("Received values:", values);
  };
  const [form] = Form.useForm();
  useEffect(() => {
    console.log("id_Type --> ", props?.user?.id_Type);
    form.setFieldsValue({
      id_Type: props?.user?.id_Type,
      id_Number: props?.user?.id_Number,
    });
  }, []);

  const submit = async (values: any) => {
    console.log("Submit", values);
    let obj = {
      id_Type: values.id_Type,
      id_Number: values.id_Number,
      id_Image1: String(file) ? String(file) : props.user.id_Image1,
    };
    setIsLoading(true);
    try {
      await props.update(obj);
    } finally {
      setIsLoading(false);
    }
  };
  const UploadFile = async (data: any) => {
    try {
      console.log(data);
      const myFile = data.file;
      let file = myFile.originFileObj;
      const name = moment(new Date()).unix();
      const myNewFile = new File([file], name + ".png", {
        type: myFile.type,
      });
      const url = URL.createObjectURL(myNewFile);
      let _upload = await FILE_UPLOAD(myNewFile);
      console.log("upload --> ", _upload);
      setFile(_upload);
    } catch (err) {
      console.log("UploadFile = = = >", err);
    }
  };
  return (
    <Container>
      <Form form={form} onFinish={submit}>
        <Card>
          <Row>
            <Col md="6">
              <label className="formLabel">Id Type</label>
              <Form.Item
                name="id_Type"
                rules={[{ required: true, message: "Select Id Type" }]}
              >
                <Select size="large" placeholder="Select leave duration">
                  <Select.Option value=" Aadhar Card">
                    Aadhar Card
                  </Select.Option>
                  <Select.Option value="Pan Card">Pan Card</Select.Option>
                  <Select.Option value="Driving License">
                    Driving License
                  </Select.Option>
                  <Select.Option value="Voter Id card">
                    Voter Id card"
                  </Select.Option>
                </Select>
              </Form.Item>
            </Col>
            <Col md="6">
              <label className="formLabel">Name (In id)</label>
              <Form.Item
                name="id_Number"
                style={{ marginBottom: 10 }}
                rules={[{ required: true }]}
              >
                <Input size="large" placeholder="" />
              </Form.Item>
            </Col>

            <Col md="6">
              <Col md="6" />
              <br />
              <br />
              <img
                style={{
                  width: "300px",
                  height: "200px",
                }}
                src={props?.user?.id_Image1}
              />
              <Form.Item name={"file"} rules={[{ required: false }]}>
                <Upload onChange={(data) => UploadFile(data)}>
                  <div
                    style={{
                      marginLeft: 10,
                      width: "100%",
                    }}
                  >
                    <UploadOutlined color="red" /> Click to Upload Id Proof
                  </div>
                </Upload>
              </Form.Item>
            </Col>
          </Row>
          <br />
          <Row>
            <Col md="6"></Col>
            <Col md="3">
              <Form.Item>
                <Button size="large" style={{ width: "100%" }}>
                  Cancel
                </Button>
              </Form.Item>
            </Col>
            <Col md="3">
              <Form.Item>
                <Button
                  size="large"
                  style={{ width: "100%" }}
                  type="primary"
                  htmlType="submit"
                  disabled={isLoading}
                >
                  {isLoading ? (
                    <Spin
                      indicator={
                        <LoadingOutlined
                          style={{ fontSize: 24, color: "#fff" }}
                          spin
                        />
                      }
                    />
                  ) : (
                    "Update"
                  )}
                </Button>
              </Form.Item>
            </Col>
          </Row>
        </Card>
      </Form>
    </Container>
  );
}

export default IdProof;
