// import { Button, Card, DatePicker, Form, Input, Spin } from "antd";
// import React, { useEffect, useState } from "react";
// import { Col, Container, Row } from "react-bootstrap";
// import dayjs from "dayjs";
// import { LoadingOutlined } from "@ant-design/icons";

// function PassportDetails(props: any) {
//   const [isLoading, setIsLoading] = useState(false);
//   const [form] = Form.useForm();

//   useEffect(() => {
//     console.log("passportNo --> ", props?.user?.passportNo);

//     form.setFieldsValue({
//       passportNo: props?.user?.passportNo,
//       passport_ExpariryDate: props?.user
//         ? dayjs(props?.user?.passport_ExpariryDate, "YYYY/MM/DD")
//         : null,
//       passport_IssueDate: props?.user
//         ? dayjs(props?.user?.passport_IssueDate, "YYYY/MM/DD")
//         : null,
//     });
//   }, [props?.user]);

//   const submit = async (values: any) => {
//     console.log("Submit", values);
//     let obj = {
//       passportNo: values.passportNo,
//       passport_ExpariryDate: values.passport_ExpariryDate,
//       passport_IssueDate: values.passport_IssueDate,
//     };

//     setIsLoading(true);
//     try {
//       await props.update(obj);
//     } finally {
//       setIsLoading(false);
//     }
//   };

//   return (
//     <Container>
//       <Form form={form} onFinish={submit}>
//         <Card>
//           <Row>
//             <Col md="6">
//               <label className="formLabel"> Passport No</label>
//               <Form.Item
//                 name="passportNo"
//                 style={{ marginBottom: 10 }}
//                 rules={[
//                   { required: true, message: "Please enter passport number" },
//                 ]}
//               >
//                 <Input size="large" placeholder="" />
//               </Form.Item>
//             </Col>
//             <Col md="6">
//               <label className="formLabel">Issued Date</label>
//               <Form.Item
//                 name="passport_IssueDate"
//                 style={{ marginBottom: 10 }}
//                 rules={[
//                   { required: true, message: "Please select issue date" },
//                 ]}
//               >
//                 <DatePicker
//                   placeholder=""
//                   size="large"
//                   style={{ width: "100%" }}
//                 />
//               </Form.Item>
//             </Col>
//             <Col md="6">
//               <label className="formLabel">Expiry Date</label>
//               <Form.Item
//                 name="passport_ExpariryDate"
//                 style={{ marginBottom: 10 }}
//                 rules={[
//                   { required: true, message: "Please select expiry date" },
//                 ]}
//               >
//                 <DatePicker
//                   placeholder=""
//                   size="large"
//                   style={{ width: "100%" }}
//                 />
//               </Form.Item>
//             </Col>
//           </Row>
//           <br />
//           <Row>
//             <Col md="6"></Col>
//             <Col md="3">
//               <Form.Item>
//                 <Button size="large" style={{ width: "100%" }}>
//                   Cancel
//                 </Button>
//               </Form.Item>
//             </Col>
//             <Col md="3">
//               <Form.Item>
//                 <Button
//                   size="large"
//                   style={{ width: "100%" }}
//                   type="primary"
//                   htmlType="submit"
//                   loading={isLoading}
//                 >
//                   Update
//                 </Button>
//               </Form.Item>
//             </Col>
//           </Row>
//         </Card>
//       </Form>
//     </Container>
//   );
// }

// export default PassportDetails;

import { Button, Card, DatePicker, Form, Input, Spin } from "antd";
import React, { useEffect, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import dayjs from "dayjs";
import { LoadingOutlined } from "@ant-design/icons";

function PassportDetails(props: any) {
  const [isLoading, setIsLoading] = useState(false);
  const [form] = Form.useForm();

  useEffect(() => {
    console.log("passportNo --> ", props?.user?.passportNo);

    form.setFieldsValue({
      passportNo: props?.user?.passportNo,
      passport_ExpariryDate: props?.user?.passport_ExpariryDate
        ? dayjs(props?.user?.passport_ExpariryDate)
        : null,
      passport_IssueDate: props?.user?.passport_IssueDate
        ? dayjs(props?.user?.passport_IssueDate)
        : null,
    });
  }, [props?.user]);

  const submit = async (values: any) => {
    console.log("Submit", values);
    let obj = {
      passportNo: values.passportNo,
      passport_ExpariryDate: values.passport_ExpariryDate
        ? values.passport_ExpariryDate.format("YYYY-MM-DD")
        : null,
      passport_IssueDate: values.passport_IssueDate
        ? values.passport_IssueDate.format("YYYY-MM-DD")
        : null,
    };

    setIsLoading(true);
    try {
      await props.update(obj);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Container>
      <Form form={form} onFinish={submit}>
        <Card>
          <Row>
            <Col md="6">
              <label className="formLabel"> Passport No</label>
              <Form.Item
                name="passportNo"
                style={{ marginBottom: 10 }}
                rules={[
                  { required: true, message: "Please enter passport number" },
                ]}
              >
                <Input size="large" placeholder="" />
              </Form.Item>
            </Col>
            <Col md="6">
              <label className="formLabel">Issued Date</label>
              <Form.Item
                name="passport_IssueDate"
                style={{ marginBottom: 10 }}
                rules={[
                  { required: true, message: "Please select issue date" },
                ]}
              >
                <DatePicker
                  placeholder=""
                  size="large"
                  style={{ width: "100%" }}
                  format="YYYY-MM-DD"
                />
              </Form.Item>
            </Col>
            <Col md="6">
              <label className="formLabel">Expiry Date</label>
              <Form.Item
                name="passport_ExpariryDate"
                style={{ marginBottom: 10 }}
                rules={[
                  { required: true, message: "Please select expiry date" },
                ]}
              >
                <DatePicker
                  placeholder=""
                  size="large"
                  style={{ width: "100%" }}
                  format="YYYY-MM-DD"
                />
              </Form.Item>
            </Col>
          </Row>
          <br />
          <Row>
            <Col md="6"></Col>
            <Col md="3">
              <Form.Item>
                <Button size="large" style={{ width: "100%" }}>
                  Cancel
                </Button>
              </Form.Item>
            </Col>
            <Col md="3">
              <Form.Item>
                <Button
                  size="large"
                  style={{ width: "100%" }}
                  type="primary"
                  htmlType="submit"
                  loading={isLoading}
                >
                  Update
                </Button>
              </Form.Item>
            </Col>
          </Row>
        </Card>
      </Form>
    </Container>
  );
}

export default PassportDetails;
