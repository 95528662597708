import { Card, Form, notification } from "antd";
import React, { useEffect } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { useState } from "react";
import { GET } from "../../utils/apiCalls";
import { API } from "../../config/api";
import { useParams } from "react-router-dom";

function Viewnotification() {
  const [form] = Form.useForm();
  const { id } = useParams();
  const [notificationData, setNotificationData] = useState<any>();
  console.log("notificationData---", notificationData);
  useEffect(() => {
    getAllData();
  }, []);

  const getAllData = async () => {
    try {
      let api = API.GET_NOTIFICATIONS_REQUEST + id;

      const Data: any = await GET(api, {});

      setNotificationData(Data?.data);
    } catch (error) {
      console.log("Error fetching leave request data:", error);
    }
  };

  return (
    <Container>
      <Card>
        <div className="productAdd-Txt1" style={{ fontSize: "15px" }}>
          Notification Details
        </div>
        <Row gutter={[16, 16]}>
          <Col span={6}>
            <strong>Title:</strong>
          </Col>
          <Col
            span={18}
            style={{
              color:
                notificationData?.title === "Leave approved" ? "green" : "red",
            }}
          >
            {notificationData?.title}
          </Col>
        </Row>
        <Row gutter={[16, 16]}>
          <Col span={6}>
            <strong>Description:</strong>
          </Col>
          <Col span={18}>
            <span>{notificationData?.description}</span>
          </Col>
        </Row>
      </Card>
    </Container>
  );
}

export default Viewnotification;
