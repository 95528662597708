// import { Button, Card, Flex, Table, Pagination } from "antd";
// import React from "react";
// import { Container } from "react-bootstrap";
// import jsPDF from "jspdf";
// import moment from "moment";
// import "jspdf-autotable";

// declare module "jspdf" {
//   interface jsPDF {
//     autoTable: (options: jsPDF.AutoTableOptions) => jsPDF;
//   }

//   namespace jsPDF {
//     interface AutoTableOptions {
//       head: Array<any[]>;
//       body: Array<any[]>;
//       startY?: number;
//     }
//   }
// }

// const DataTable = (props: any) => {
//   console.log("props", props);
//   const handleDownload = () => {
//     const doc = new jsPDF();

//     doc.setFont("helvetica");
//     doc.setFontSize(20);
//     doc.setTextColor(0, 0, 255);
//     doc.text("Pay Slip", 105, 10, { align: "center" });

//     props?.list?.payrollData?.forEach((record: any, index: number) => {
//       doc.setFontSize(12);
//       doc.setTextColor(0);

//       const hr_finance: any = [
//         ["category", record?.category || ""],
//         ["amount", record?.amount || ""],
//         ["date", record?.date || ""],
//       ];

//       props?.list?.projects?.forEach((record: any, index: number) => {
//         if (index > 0) {
//           doc.addPage();
//         }

//         doc.setFontSize(12);
//         doc.setTextColor(0);

//         const employeeDetails = [
//           ["Name", record?.employee.fullName || ""],
//           ["Role", record.employee?.role || ""],
//           ["Salary", record.salary || ""],
//           ["In Amount", record.in_amount || ""],
//           ["Out Amount", record.out_amount || ""],
//           ["Total Amount", record.total_amount || ""],
//           ["Date", moment(record.createdAt).format("DD-MM-YYYY") || ""],
//           ["Email", record.employee?.email || ""],
//           ["Address", record.employee?.address || ""],
//           ["Phone Number", record.employee?.phone || ""],
//           ["Account Number", record.employee?.accountNo || ""],
//           ["Bank Name", record.employee?.bankName || ""],
//           ["IFSC code", record.employee?.ifsc || ""],
//           ["Branch Name", record.employee?.branch || ""],
//         ];

//         doc.autoTable({
//           startY: 20,
//           head: [["Field", "Value"]],
//           body: [employeeDetails, hr_finance],
//         });
//       });
//     });

//     doc.save("payroll_slip.pdf");
//   };
//   const columns = [
//     {
//       title: "Name",
//       dataIndex: "name",
//       key: "name",
//       render: (_: any, record: any) => (
//         <span>{record?.employee?.fullName}</span>
//       ),
//     },
//     { title: "Salary", dataIndex: "salary", key: "salary" },
//     { title: "In Amount", dataIndex: "in_amount", key: "in_amount" },
//     { title: "Out Amount", dataIndex: "out_amount", key: "out_amount" },
//     { title: "Total Amount", dataIndex: "total_amount", key: "total_amount" },
//     {
//       title: "Date",
//       dataIndex: "createdAt",
//       key: "createdAt",
//       render: (text: any) => moment(text).format("DD-MM-YYYY"),
//     },
//   ];

//   return (
//     <>
//       <Container>
//         <Card>
//           <h5>Pay Slip</h5>

//           <br />

//           <div style={{ display: "flex", justifyContent: "flex-end" }}>
//             <Button
//               type="primary"
//               onClick={handleDownload}
//               style={{
//                 marginBottom: "20px",
//               }}
//             >
//               Download PDF
//             </Button>
//           </div>
//           <Table
//             dataSource={props?.list?.projects}
//             columns={columns}
//             rowKey="id"
//             pagination={false}
//           />
//         </Card>
//         <Pagination
//           total={props?.meta?.total_count}
//           pageSize={props?.meta?.take}
//           current={props?.meta?.current_page}
//           onChange={(page, pageSize) => props?.onPageChange(page, pageSize)}
//           style={{
//             display: props?.meta?.total_count > 10 ? "block" : "none",
//             textAlign: "right",
//             marginTop: "20px",
//           }}
//         />
//         <div style={{ display: "none" }}>
//           {props?.list?.data?.map((record: any, index: number) => (
//             <Card key={index} className="card" style={{ marginBottom: "20px" }}>
//               <p>
//                 <strong>Name:</strong> {record.name}
//               </p>
//               <p>
//                 <strong>Role:</strong> {record.employee?.role}
//               </p>
//               <p>
//                 <strong>Salary:</strong> {record.salary}
//               </p>
//               <p>
//                 <strong>In Amount:</strong> {record.in_amount}
//               </p>
//               <p>
//                 <strong>Out Amount:</strong> {record.out_amount}
//               </p>
//               <p>
//                 <strong>Total Amount:</strong> {record.total_amount}
//               </p>
//               <p>
//                 <strong>Date:</strong>{" "}
//                 {moment(record.createdAt).format("DD-MM-YYYY")}
//               </p>

//               <p>
//                 <strong>Address:</strong> {record.employee?.address}
//               </p>
//               <p>
//                 <strong>Email:</strong> {record.employee?.email}
//               </p>

//               <p>
//                 <strong>Phone Number:</strong> {record.employee?.phone}
//               </p>
//               <p>
//                 <strong>Account Number:</strong> {record.employee?.accountNo}
//               </p>
//               <p>
//                 <strong>Bank Name:</strong> {record.employee?.bankName}
//               </p>
//               <p>
//                 <strong>IFSC code :</strong> {record.employee?.ifsc}
//               </p>
//               <p>
//                 <strong>date :</strong> {record.payrollData?.date}
//               </p>
//               <p>
//                 <strong>IFSC code :</strong> {record.employee?.ifsc}
//               </p>
//               <p>
//                 <strong>IFSC code :</strong> {record.employee?.ifsc}
//               </p>
//               <p>
//                 {/* <strong>Branch Name:</strong> {record.employee?.branch} */}
//               </p>
//             </Card>
//           ))}
//         </div>
//       </Container>
//     </>
//   );
// };

// export default DataTable;

import { Button, Card, Table, Pagination } from "antd";
import React from "react";
import { Container } from "react-bootstrap";
import jsPDF from "jspdf";
import moment from "moment";
import "jspdf-autotable";

declare module "jspdf" {
  interface jsPDF {
    autoTable: (options: jsPDF.AutoTableOptions) => jsPDF;
    lastAutoTable: { finalY: number };
  }

  namespace jsPDF {
    interface AutoTableOptions {
      head: Array<any[]>;
      body: Array<any[]>;
      startY?: number;
    }
  }
}

const DataTable = (props: any) => {
  console.log("props", props);

  const handleDownload = () => {
    const doc = new jsPDF();

    doc.setFont("helvetica");
    doc.setFontSize(20);
    doc.setTextColor(0, 0, 255);
    doc.text("Pay Slip", 105, 10, { align: "center" });

    props?.list?.projects?.forEach((project: any, projectIndex: number) => {
      if (projectIndex > 0) {
        doc.addPage();
      }

      const employeeDetails = [
        ["Name", project?.employee.fullName || "----"],
        ["Role", project.employee?.role || "----"],
        ["Date", moment(project.createdAt).format("DD-MM-YYYY") || "----"],
        ["Email", project.employee?.email || "-----"],
        ["Address", project.employee?.address || "----"],
        ["Phone Number", project.employee?.phone || "---"],
        ["Account Number", project.employee?.accountNo || "----"],
        ["Bank Name", project.employee?.bankName || "----"],
        ["IFSC code", project.employee?.ifsc || "----"],
        ["Branch Name", project.employee?.branch || "---"],
      ];

      doc.autoTable({
        startY: 20,
        head: [["Field", "Value"]],
        body: employeeDetails,
      });

      const hr_finance =
        props?.list?.payrollData?.map((record: any) => [
          record?.category || "-----",
          record?.type || "-----",

          record?.amount || "----",
          moment(record?.date).format("DD-MM-YYYY") || "-----",
        ]) || [];

      if (hr_finance.length > 0) {
        doc.autoTable({
          startY: doc.lastAutoTable.finalY + 10,
          head: [["Category", "type", "amount"]],
          body: hr_finance,
        });
      }
      const generatedPayslips =
        props?.list?.generatedPayslips?.data?.map((record: any) => [
          // record?.category || "",
          // record?.amount || "",
          // moment(record?.date).format("DD-MM-YYYY") || "",

          record.salary || "-----",
          record.in_amount || "----",
          record.out_amount || "----",
          record.total_amount || "----",
        ]) || [];

      if (generatedPayslips.length > 0) {
        doc.autoTable({
          startY: doc.lastAutoTable.finalY + 10,
          head: [["Salary", "In Amount", "Out Amount", "Total Amount"]],
          body: generatedPayslips,
        });
      }
    });

    doc.save("payroll_slip.pdf");
  };

  const columns = [
    {
      title: "Name",
      // dataIndex: ["employee", "fullName"],
      dataIndex: "name",
      key: "name",
    },
    { title: "Salary", dataIndex: "salary", key: "salary" },
    { title: "In Amount", dataIndex: "in_amount", key: "in_amount" },
    { title: "Out Amount", dataIndex: "out_amount", key: "out_amount" },
    { title: "Total Amount", dataIndex: "total_amount", key: "total_amount" },
  ];

  return (
    <Container>
      <Card>
        <h5>Pay Slip</h5>
        <br />
        <div style={{ display: "flex", justifyContent: "flex-end" }}>
          <Button
            type="primary"
            onClick={handleDownload}
            style={{ marginBottom: "20px" }}
          >
            Download PDF
          </Button>
        </div>
        <Table
          dataSource={props?.list?.generatedPayslips?.data}
          columns={columns}
          rowKey="id"
          pagination={false}
        />
      </Card>
      <Pagination
        total={props?.meta?.total_count}
        pageSize={props?.meta?.take}
        current={props?.meta?.current_page}
        onChange={(page, pageSize) => props?.onPageChange(page, pageSize)}
        style={{
          display: props?.meta?.total_count > 10 ? "block" : "none",
          textAlign: "right",
          marginTop: "20px",
        }}
      />
    </Container>
  );
};

export default DataTable;
