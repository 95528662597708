import React from "react";
import Header from "../../components/header";

const Users = () => {
  return (
    <div className="screen-box">
      <div className="main-box">
        <div className="main-txt">
          <Header header="Users" btn onBtnClick={() => console.log("hello")} />
        </div>
      </div>
    </div>
  );
};

export default Users;
