import "./App.css";
import { Route, Routes } from "react-router-dom";
import { ConfigProvider } from "antd";
import ThemeObj from "./theme";
import Navigation from "./navigation";
import ProtectedRoute from "./navigation/protectRouter";
import { useSelector } from "react-redux";
import Login from "./website/login";
import "./App.scss";
import AdminNavigation from "./adminNavigation";

function App() {
  const User = useSelector((state: any) => state.User);

  return (
    <ConfigProvider theme={ThemeObj}>
      <Routes>
        <Route index element={<Login />} />

        <Route
          path="/Admin/*"
          element={
            <ProtectedRoute isSignedIn={User.admin && User.auth}>
              <AdminNavigation />
            </ProtectedRoute>
          }
        />

        <Route
          path="/Auth/*"
          element={
            <ProtectedRoute isSignedIn={User.auth && !User.admin}>
              <Navigation />
            </ProtectedRoute>
          }
        />
      </Routes>
    </ConfigProvider>
  );
}

export default App;
