export const API = {
  // BASE_URL: "http://localhost:8024/taxgov2/",
  BASE_URL: "https://server.bairuhatech.com/employee-server/taxgov2/",

  //Login
  LOGIN: "employee/login", //POST
  //Leave
  POST_LEAVE_REQUESTS: "leaves",
  GET_LEAVE_REQUESTS: "leaves/",
  POST_FILTER_LEAVE: "leaves/filter",
  POST_LEAVE_REQUEST: "leaves/approveLeave/",
  UPDATE_LEAVE_REQUEST: "leaves/",
  POST_PAGE_LEAVE: "leaves/page",
  POST_PAGE_LEAVES: "notification/page",

  GET_LEAVE_COUNT: "leaves/balance/",

  //Employee
  GET_HR_EMPLOYEE_LIST: "employee/",
  UPDATE_EMPLOYEE: "employee/update/",
  PASSWORD_UPDATE: "employee/updatePassword/",
  //notification
  GET_NOTIFICATIONS_REQUESTS: "notification/user/",
  GET_NOTIFICATIONS_REQUEST: "notification/",
  POST_PAGE_NOTIFICATION: "notification/page",

  //Dashboard

  //members
  GET_USERID_FILTER: "members/byUser/",
  MEMBER_LIST: "members/page",

  // LOGIN: "employee/login",
  HR_DASHBOARD: "employee/HrDashboard",

  //USER DASHBOARD
  GET_USRDASHBOARD: "employee/UsrDashboard/",

  //TIMESHEET
  POST_TIMESHEET: "timeSheet",
  GET_TIMESHEET_FILTER: "timeSheet/filter",
  GET_ONE_TIMESHEET: "timeSheet/",
  GET_TIMESHEET: "timeSheet",
  POST_PAGE_TIMESHEET: "timeSheet/list",

  //PROJECT
  GET_PROJECT: "project",

  //TASK
  GET_FILTER_TASK: "task/filter/",
  GET_TASK: "task/",
  PUT_TASK: "task/update/",
  GET_FILTERTASK_TASK: "task/filtertask/",
  POST_PAGE_TASK: "task/list",

  //comments
  COMMENTS: "comments",
  POST_PAGE_COMMENTS: "comments/page",

  // calendar
  CALENDAR: "calendar",
  GET_CALENDAR: "calendar/",

  //payroll
  POST_HR_PAYROLL: "Hr_Payroll/page",
};
