import React, { useEffect, useState } from "react";
import { Container } from "react-bootstrap";
import moment from "moment";
import { API } from "../../config/api";
import { GET, POST } from "../../utils/apiCalls";
import { useSelector } from "react-redux";
import { Card, Pagination } from "antd";

import { useNavigate } from "react-router-dom";
import { useForm } from "antd/es/form/Form";

function Notifications() {
  const [form] = useForm();
  const [isLoading, setIsLoading] = useState(false);
  const [data, setData] = useState<any[]>([]);
  const [page, setPage] = useState(1);
  const [take, setTake] = useState(10);
  const [meta, setMeta] = useState<any>({});
  const navigate = useNavigate();
  const User = useSelector((state: any) => state.User.user);

  const fetchHrNotifications = async (page: any, take: any) => {
    try {
      setIsLoading(true);
      let obj = {
        employeeId: User.id,
        query: form.getFieldValue("query"),
        page: Number(page),
        take: 10,
      };
      const url = API.POST_PAGE_NOTIFICATION;
      const data: any = await POST(url, obj);

      setData(data?.data);
      setMeta(data?.meta);

      setIsLoading(false);
      console.log("data:", data);
    } catch (error) {
      console.log(error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchHrNotifications(1, 10);
  }, [page, take]);

  return (
    <>
      <Container>
        <Card>
          <div className="productAdd-Txt1" style={{ textAlign: "center" }}>
            <h5> Notifications Details</h5>
            <br />
          </div>
          <h6>Total {meta?.total_count} Notifications</h6>
          <br />
          {data?.map((notification, index) => (
            <Card
              key={index}
              style={{ marginBottom: "20px" }}
              onClick={() =>
                navigate(
                  `/Auth/notification/Viewnotification/${notification.id}`
                )
              }
            >
              <Card.Meta
                title={
                  <p
                    style={{
                      color:
                        notification.title === "Leave approved"
                          ? "green"
                          : "red",
                    }}
                  >
                    {notification.title}
                  </p>
                }
                description={
                  <>
                    <p style={{ color: "#333" }}>
                      {moment(notification.createdAt).format(
                        "MMMM Do YYYY, h:mm:ss a"
                      )}
                    </p>
                  </>
                }
              />
            </Card>
          ))}
        </Card>
      </Container>

      <Pagination
        defaultCurrent={meta?.page}
        total={meta?.total_count}
        onChange={(page, take) => {
          fetchHrNotifications(page, take);
        }}
        style={{
          display: meta.total_count > 10 ? "block" : "none",
          textAlign: "right",
          marginTop: "20px",
        }}
      />
    </>
  );
}

export default Notifications;
