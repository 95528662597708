import { useNavigate, useLocation } from "react-router-dom";
import Menu from "./menu.json";
import "./styles.scss";
import DynamicIcon from "./dymanicIcon";

import Logo from "../assets/images/bairuha-new-logo-logo-White.png";
import { useState } from "react";
import { MdMenuOpen, MdOutlineMenu } from "react-icons/md";

function SideBar(props: any) {
  const [toggle, setToggle] = useState(false);
  const navigate = useNavigate();
  let location = useLocation();

  return (
    <>
      <div className="main">
        <div className="toggleButton">
          {props?.collapsed ? (
            <MdOutlineMenu
              size={30}
              color="#fff"
              onClick={() => props.doCollapse(!toggle)}
            />
          ) : (
            <MdMenuOpen
              size={30}
              color="#fff"
              onClick={() => props.doCollapse(!toggle)}
            />
          )}
        </div>
        <div
          className="sideBar-Box"
          style={{ width: props?.collapsed ? 80 : 250 }}
        >
          <div className="sideBar-logo">
            <img width={props?.collapsed ? 30 : 50} alt="E-Learn" src={Logo} />
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "stretch",
            }}
          >
            {Menu?.slice(0, 7).map((item: any) => {
              return (
                <div
                  key={item?.id}
                  onClick={() => navigate(item?.navigate)}
                  className={
                    location.pathname === item?.navigate
                      ? "sideBar-item active"
                      : "sideBar-item"
                  }
                >
                  {/* <DynamicIcon icon={item?.icon} />
                <div style={{ marginLeft: 20 }} />
                <div>{item?.title}</div>
              </div>
            );
          })}
        </div> */}
                  <DynamicIcon icon={item?.icon} />
                  {!props?.collapsed ? (
                    <>
                      <div style={{ marginLeft: 20 }} />
                      <div>{item?.title}</div>
                    </>
                  ) : null}
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </>
  );
}
export default SideBar;
