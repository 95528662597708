import { LoadingOutlined } from "@ant-design/icons";
import { Button, Card, Form, Input, Spin } from "antd";
import React, { useEffect, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";

function ContactDetails(props: any) {
  const [isLoading, setIsLoading] = useState(false);
  const [form] = Form.useForm();

  useEffect(() => {
    console.log("officeEmail --> ", props?.user?.officeEmail);

    form.setFieldsValue({
      officeEmail: props?.user?.officeEmail,
      phone: props?.user?.phone,
      email: props?.user?.email,
      altPhone: props?.user?.altPhone,
    });
  }, []);

  const submit = async (values: any) => {
    console.log("Submit", values);
    let obj = {
      officeEmail: values.officeEmail,
      phone: values.phone,
      email: values.email,
      altPhone: values.altPhone,
    };

    setIsLoading(true);
    try {
      await props.update(obj);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Container>
      <Form form={form} onFinish={submit}>
        <Card>
          <Row>
            <Col md="6">
              <Form.Item
                name="officeEmail"
                label="Office Email"
                rules={[
                  {
                    required: true,
                    message: "Please enter office email",
                  },
                  {
                    type: "email",
                    message: "Please enter a valid email address",
                  },
                ]}
              >
                <Input placeholder="" />
              </Form.Item>
            </Col>
            <Col md="6">
              <Form.Item
                name="phone"
                label="Phone"
                rules={[
                  {
                    required: true,
                    message: "Please enter phone number",
                  },
                ]}
              >
                <Input placeholder="" />
              </Form.Item>
            </Col>
            <Col md="6">
              <Form.Item
                name="email"
                label="Email"
                rules={[
                  {
                    required: true,
                    message: "Please enter office email",
                  },
                  {
                    type: "email",
                    message: "Please enter a valid email address",
                  },
                ]}
              >
                <Input placeholder="" />
              </Form.Item>
            </Col>
            <Col md="6">
              <Form.Item
                name="altPhone"
                label="Alternative Phone"
                rules={[
                  {
                    required: true,
                    message: "Please enter phone number",
                  },
                ]}
              >
                <Input placeholder="" />
              </Form.Item>
            </Col>
          </Row>
          <br />
          <Row>
            <Col md="6"></Col>
            <Col md="3">
              <Form.Item>
                <Button size="large" style={{ width: "100%" }}>
                  Cancel
                </Button>
              </Form.Item>
            </Col>
            <Col md="3">
              <Form.Item>
                <Button
                  size="large"
                  style={{ width: "100%" }}
                  type="primary"
                  htmlType="submit"
                  loading={isLoading}
                >
                  Update
                </Button>
              </Form.Item>
            </Col>
          </Row>
        </Card>
      </Form>
    </Container>
  );
}

export default ContactDetails;
