import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { API } from "../../config/api";
import { FILE_UPLOAD, GET, PUT } from "../../utils/apiCalls";
import { Col, Form, Tabs, Upload, notification } from "antd";
import PersonalDetails from "./components/personalDetails";
import ContactDetails from "./components/contactDetails";
import BankDetails from "./components/bankDetails";
import IdProof from "./components/idProof";
import PassportDetails from "./components/passportDetails";
import { Container } from "react-bootstrap";
import { UploadOutlined } from "@ant-design/icons";
import moment from "moment";
import UpdatePassword from "./components/updatePassword";

const { TabPane } = Tabs;

function Index() {
  const [isLoading, setIsLoading] = useState(false);
  const [isLoading2, setIsLoading2] = useState(false);
  const [userData, setUserData] = useState(null);

  const [form] = Form.useForm();
  const User = useSelector((state: any) => state.User.user);
  console.log("User--", User);

  useEffect(() => {
    getUserData();
  }, []);

  const getUserData = async () => {
    try {
      setIsLoading(true);
      const url = API.GET_HR_EMPLOYEE_LIST + User.id;
      const { data }: any = await GET(url, null);
      setUserData(data);
      console.log(data);
    } catch (error) {
      console.log(error);
    } finally {
      setIsLoading(false);
    }
  };

  const submitHandler = async (values: any) => {
    try {
      console.log("values --> ", values);
      setIsLoading2(true);
      let api = API.UPDATE_EMPLOYEE + User.id;
      let postRequest: any = await PUT(api, values);
      if (postRequest.status) {
        notification.success({ message: "Employee Updated" });
        getUserData();
      } else {
        notification.error({ message: "Failed to Update" });
      }
    } catch (error) {
      console.log(error);
      notification.error({
        message: "Something went wrong! Please try again later",
      });
    } finally {
      setIsLoading2(false);
    }
  };

  return (
    <>
      {isLoading ? (
        <h1>Loading</h1>
      ) : (
        <Container>
          <Tabs defaultActiveKey="1">
            <TabPane tab="Personal Details" key="1">
              <PersonalDetails user={userData} update={submitHandler} />
            </TabPane>
            <TabPane tab="Contact Details" key="2">
              <ContactDetails user={userData} update={submitHandler} />
            </TabPane>
            <TabPane tab="Bank Details" key="3">
              <BankDetails user={userData} update={submitHandler} />
            </TabPane>
            <TabPane tab="Id Proof" key="4">
              <IdProof user={userData} update={submitHandler} />
            </TabPane>
            <TabPane tab="Passport Details" key="5">
              <PassportDetails user={userData} update={submitHandler} />
            </TabPane>
            <TabPane tab="Change Password" key="6">
              <UpdatePassword user={userData} update={submitHandler} />
            </TabPane>
          </Tabs>
        </Container>
      )}
    </>
  );
}

export default Index;
