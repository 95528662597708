import react, { useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { API } from "../../config/api";
import { POST } from "../../utils/apiCalls";
import { login } from "../../redux/slice/userSlice";
import { notification } from "antd";
import React from "react";
import "./styles.scss";
import { Col, Container, Row } from "react-bootstrap";
import { Button, Form, Input } from "antd";
// import Logo from "../../assets/images/logo2.svg";
import Logo from "../../assets/images/bairuha-new-logo-logo-White.png";

const Login = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);

  const onFinish = async (values: any) => {
    try {
      setLoading(true);
      let obj = {
        email: values.user,
        password: values.password,
      };
      let url = API.LOGIN;
      let { data, status, message }: any = await POST(url, obj);
      if (status) {
        setLoading(false);
        notification.success({ message });
        dispatch(login({ ...data }));
        if (data?.type === "Admin") {
          navigate("/Admin");
        } else {
          navigate("/Auth");
        }
      } else {
        setLoading(false);
        notification.error({ message });
      }
    } catch (error) {
      setLoading(false);
      console.error("Login failed:", error);
    }
  };

  return (
    <div>
      <Container>
        <div className="loginScreen">
          <Row>
            <Col sm={4}></Col>
            <Col sm={4}>
              <div className="loginScreen-Box3">
                <img src={Logo} alt="" className="loginScreen-logo" />
              </div>
              <div className="loginScreen-txt1">Login and Continue</div>
              <Form onFinish={onFinish}>
                <label className="formLabel">Email or Username</label>
                <Form.Item
                  name={"user"}
                  rules={[{ required: true, message: "Required" }]}
                >
                  <Input size="large" />
                </Form.Item>
                <label className="formLabel">Password</label>
                <Form.Item
                  name={"password"}
                  rules={[{ required: true, message: "Required" }]}
                >
                  <Input.Password size="large" />
                </Form.Item>
                <br />
                <Button
                  block
                  size="large"
                  htmlType="submit"
                  type="primary"
                  loading={loading}
                  style={{ height: 50 }}
                >
                  Sign In
                </Button>
              </Form>
            </Col>
            <Col sm={4}></Col>
          </Row>
        </div>
      </Container>
      {/* <Footer /> */}
    </div>
  );
};

export default Login;
