import React, { useState } from "react";
import { Route, Routes } from "react-router-dom";
import Dashboard from "../admin/dashboard";
import { useSelector } from "react-redux";
import ProtectedRoute from "./protectRouter";
import { Layout, theme } from "antd";
import SideBar from "./sideBar";
import LayoutHeader from "./layoutHeader";
// import Search from "../app/search";
import Settings from "../app/settings";
import Help from "../app/help";
import Profile from "../app/profile";

import { useNavigate } from "react-router-dom";
import Leaves from "../app/leaves";
import Notifications from "../app/notifcation";
// import CourseDetailsScreen from "../app/course-details";
// import ModuleScreen from "../app/modules";
// import LessonScreen from "../app/Lesson";
// import CourseDetailsScreen from "../app/course-details";
import Timesheet from "../app/timesheet";
import Editprofile from "../app/profile";
import Project from "../app/project";
import DashBoard from "../app/Dashboard";

import Task from "../app/task";
import TaskView from "../app/task/view";
import ViewLevaeDetails from "../app/leaves/view";
import Viewnotification from "../app/notifcation/view";
import Documents from "../app/Documents";

const Navigation = () => {
  const User = useSelector((state: any) => state.User);
  const [collapsed, setCollapsed] = useState(false);
  const { Sider, Content } = Layout;

  const {
    token: { colorBgContainer, borderRadiusLG },
  } = theme.useToken();
  return (
    <>
      <Layout>
        {/* <Sider
          style={{ backgroundColor: "#f26929", height: "100vh" }}
          width={250}
        >
          <SideBar />
        </Sider> */}
        <Sider
          style={{ backgroundColor: " rgb(28, 112, 110)", height: "100vh" }}
          trigger={null}
          collapsible
          collapsed={collapsed}
          width={collapsed ? 40 : 250}
        >
          <SideBar
            collapsed={collapsed}
            doCollapse={() => setCollapsed(!collapsed)}
          />
        </Sider>
        <Layout>
          <LayoutHeader doCollapse={() => setCollapsed(!collapsed)} />
          {/* <LayoutHeader /> */}
          <Content style={{ background: "#f5fffd" }}>
            <Routes>
              <Route path="/" element={<DashBoard />} />
              <Route path="/leaves/" element={<Leaves />} />
              <Route path="/documents/" element={<Documents />} />

              <Route path="/settings" element={<Settings />} />
              <Route path="/help" element={<Help />} />
              <Route path="/profile" element={<Profile />} />
              <Route path="/notification" element={<Notifications />} />
              <Route
                path="/notification/Viewnotification/:id"
                element={<Viewnotification />}
              />
              <Route path="/editprofile" element={<Editprofile />} />
              <Route path="/timesheet" element={<Timesheet />} />
              <Route path="/project/task-details/:id" element={<Task />} />
              <Route path="/project" element={<Project />} />
              <Route path="project/taskView/:id" element={<TaskView />} />
              <Route
                path="/leaves/ViewLevaeDetails/:id"
                element={<ViewLevaeDetails />}
              />
            </Routes>
          </Content>
        </Layout>
      </Layout>
    </>
  );
};

export default Navigation;
